#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyIco::before,
.storeGroup #topLinkMenu ul.TopLinkMenu li a span,
#divBagTotalLink a#aBagLink .bagIcon::before,
#accountMain span,
.mp-container .mp-menu .mp-level #homeMenu li > a.mp-close,
.mp-container .mp-menu .mp-level ul > li a.mp-close,
.dvSearch .TextBoxClear,
.mp-container .mp-menu .mp-level ul > li a.mp-back,
.mp-container .mp-menu .mp-level .shop ul > li.mmHasChild > a > .MobMenChevron,
.mp-container .mp-menu .MobMenIcon.glyphicon,
.s-maincontent-container.HoF-browse .columnselector li a,
#ProductContainer .hotspotbuy .WishIcon,
#ProductContainer .hotspotbuy .QuickLookIcon,
.PinWrapText .hsColourSelection::after,
.PinWrapText .hsSizeSelection::after,
.socialSitesWrapper li a,
.back-to-top,
.footerAppLine li a,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink,
.paginationWrapper .PrevNextButtons.PageNumber .NextLink,
.lillBasket #divBagItems #divBagItemsChild #ulBag li a.removeClass,
.PreviousEnable,
.NextEnable,
.PreviousDisable,
.NextDisable,
.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-right::before,
.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-left::before,
.FlanProdDet .mainImageButtonPrev span::before,
.FlanProdDet .mainImageButtonNext span::before,
.FlanProdDet .mainImageButtons .zoomButton .glyphicon::before,
.FlanProdDet .pdpWishListIco,
.FlanProdDet .pd-accordion .acc-title h3::after,
#divShareButton .ShareIcons,
.ps-swiper-button-next.swiper-button-next,
.ps-swiper-button-prev.swiper-button-prev,
.rv-swiper-button-next.swiper-button-next,
.rv-swiper-button-prev.swiper-button-prev,
#hotspotModal .wishlistShortcut,
.flanProdList .productFilterTitleBox .glyphicon::before,
.flanProdList .brandSearchSort #spnSearchIcon::before,
.HoF-browse .mobDdClose,
#hotspotModal .swiper-button-next,
#hotspotModal .swiper-button-prev,
.selectedFilters li.selectedFilter > a::before,
.mp-container .mp-menu .mobLowerMenu .MobMenIcon.glyphicon::before,
.MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a::before,
.MobileBreadcrumbMenuEnabled .currentPageBreadcrumb::before,
.ChildCategoriesListWrapper #ChildCategoriesList li a::after,
.flanProdList #mobControlBar #filterByMob .mobFilterAppIcon::before,
#addressbook-list ul > div li .addressbook-options-buttons .SmallerButtons span,
.recogniseRegisterForm .dnnFormError::before,
.FlanProdDet #SizeSelectionModal #SizeModalDropDownWrapper::after,
#topMenuWrapper2 .UserLogin a::after,
.MobileIcons a,
.bsheaderIcons .ico,
.mp-container .mp-menu .mp-level .mobMenuGroup .has-dropdown a .MobMenChevron,
.MenuCloseActive,
.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
.accordionMenuContentWrap #liMobileCurrencySelector > a::after,
.ToplinksGroup .search .searchDropdownClose,
.flanProdList .FiltersText::after,
.flanProdList .sortbyfilter .lblSortBy::after,
.flanProdList #CollapseDiv .productFilterTitle::after,
.flanProdList .productFilters .productFilterTitle::after,
.flanProdList #filterByMob .MobFiltersText::after,
.flanProdList .Responsive .mobDdClose .clsFilterIcon,
#ModSearchClose,
.FlanProdDet #productDescReturns .collapsed .glyphicon-chevron-right::before,
.FlanProdDet #productDescReturns .glyphicon-chevron-right::before,
.FooterGroup .FooterHeader .glyphicon::before,
.flanProdList #filterlist .filter-values .backTitleContainer .btn-back {
    background-image: url("/images/core/jack-wills-sprite-sheet-2021-v6.svg");
}

#hotspotModal .modal-header .close,
#PromotionDetailModal .modal-header .close {
    background-image: url("/images/core/jack-wills-sprite-sheet-2021-v6.svg") !important;
}

#PromotionDetailModal .modal-header .close {
    border: none;
    width: 20px;
    height: 20px;
    background-color: transparent !important;
    background-position: -163px -133px !important;
    background-size: 650px 650px !important;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap a:focus,
.ImgButWrap input {
    letter-spacing: 0.1em;
    line-height: 1.1em;
    font-weight: var(--fw-bold);
    text-shadow: none;
}

.ui-autocomplete {
    /* override low value added by JS*/
    z-index: 6000 !important;
    box-shadow: none !important;
    max-height: calc(100vh - 165px);
}

.ui-autocomplete li .ui-corner-all:hover,
.ui-autocomplete li .ui-corner-all:focus,
.ui-autocomplete .ui-state-focus {
    background-color: transparent;
}

#divLanguageSelector {
    display: none !important;
}

.ContentWrapper {
    padding: 10px 20px;
}

.ContentWrapper.max-2560 {
    max-width: 2560px;
    padding: 0;
}

.ContentWrapper.max-1920 {
    max-width: 1920px;
}

.Basket .ContentWrapper.max-1920,
.Login .ContentWrapper.max-1920,
.Registration .ContentWrapper.max-1920 {
    max-width: 1440px;
}

.allLettersBrand {
    text-align: left;
    border-top: 0;
}

#topMenu .SubMenuWrapper > ul::before,
#topMenu .SubMenuWrapper > ul::after {
    content: "";
    display: table;
}

#topMenu .SubMenuWrapper > ul::after {
    clear: both;
}

.SubMenuWrapper .newImages .postImage img {
    width: 100%;
}

nav#topMenu ul li.root.MenuGroupNew .SubMenuWrapper > ul > li.Center {
    width: 100%;
}

nav#topMenu > ul li.columnGroup {
    width: 33.3333%;
    padding: 5px 0;
}

nav#topMenu .columnGroup-50 ul li.columnGroup {
    width: 50%;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.columnGroup {
    width: 33.333333%;
    padding: 0;
}

#topMenuWrapper nav#topMenu > ul li.columnGroup > ul {
    padding: 0 5px;
    display: inline-block;
    min-width: 191px;
}

#topMenuWrapper nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.columnGroup > ul {
    padding: 0;
    text-align: left;
    display: block;
}

#topMenuWrapper nav#topMenu li.columnGroup li.level1 {
    border: none;
    text-align: left;
}

#topMenuWrapper nav#topMenu li.columnGroup li.level1:hover > a {
    font-weight: var(--fw-bold);
}

#topMenuWrapper nav#topMenu li.columnGroup li.level1.sdmColHeader > ul,
#topMenuWrapper nav#topMenu li.columnGroup li.level1.sdmColHeader.sdmColGap {
    margin-bottom: 20px;
}

#topMenuWrapper nav#topMenu li.columnGroup li.level1.shopAll,
#topMenuWrapper nav#topMenu li.columnGroup li.level2.shopAll {
    margin-top: 5px;
}

#topMenuWrapper nav#topMenu li.columnGroup li.level1.shopAll a,
#topMenuWrapper nav#topMenu li.columnGroup li.level2.shopAll a {
    font-weight: var(--fw-bold);
}

nav#topMenu li.root ul li ul li ul li ul {
    border: none;
}

nav#topMenu > ul li .singlecolumnadvert .singleAdContainer {
    margin-bottom: 10px;
}

nav#topMenu > ul li .singlecolumnadvert .singleAdContainer .singleAdImage {
    position: relative;
}

nav#topMenu > ul li .singlecolumnadvert .singleAdContainer .singleAdImage::before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background-color: #000;
    opacity: 0;
}

nav#topMenu > ul li .singlecolumnadvert .singleAdContainer a:hover .singleAdImage::before {
    opacity: 0.5;
}

nav#topMenu > ul li .singlecolumnadvert .view-button {
    font-size: 1.1em;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a {
    font-weight: var(--fw-bold);
    min-height: 0;
    min-width: 0;
    max-width: none;
    line-height: 1.2em;
    padding-bottom: 10px;
    font-size: 1em !important;
}

nav#topMenu li.root .SubMenuWrapper > ul .disablelink > a {
    pointer-events: none;
    cursor: text;
}

nav#topMenu li.root .SubMenuWrapper > ul .saleRed > a,
nav#topMenu ul li.root.saleGroup > a {
    color: #ec072a;
}

nav#topMenu li.root .SubMenuWrapper > ul li a {
    padding: 6px 10px;
    min-width: 0;
    max-width: none;
    letter-spacing: 0;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a:hover,
nav#topMenu li.root .SubMenuWrapper .newImages .postLink:hover,
.FooterGroupLink li a:hover,
#FiltersAndProductsWrapper .s-producttext-top-wrapper a:hover span {
    text-decoration: underline;
}

nav#topMenu li.root .SubMenuWrapper > ul li.newTag > a {
    max-width: calc(100% - 35px);
    display: inline-block;
    width: auto;
}

nav#topMenu li.root .SubMenuWrapper > ul li.trendingTag > a {
    max-width: calc(100% - 65px);
    display: inline-block;
    width: auto;
}

nav#topMenu li.root .SubMenuWrapper > ul li.newTag > div,
nav#topMenu li.root .SubMenuWrapper > ul li.trendingTag > div {
    display: none;
}

.mp-menu > .mp-level ul li.newTag .menuitemtext::after,
nav#topMenu > ul li.newTag::after {
    content: "NEW";
    padding: 3px;
    vertical-align: top;
    margin-top: 4px;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    background: #20419a;
    display: inline-block;
    width: auto;
    height: auto;
}

.mp-menu > .mp-level ul li.trendingTag .menuitemtext::after,
nav#topMenu > ul li.trendingTag::after {
    content: "TRENDING";
    padding: 3px;
    vertical-align: top;
    margin-top: 4px;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    background: #20419a;
    display: inline-block;
    width: auto;
    height: auto;
}

nav#topMenu li.root .SubMenuWrapper > ul li.boldRed a {
    color: #e43a36;
    font-weight: var(--fw-bold);
}

nav#topMenu li.root .SubMenuWrapper > ul li.boldBlue a {
    color: #20419a;
    font-weight: var(--fw-bold);
}

.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll a {
    text-transform: uppercase;
}

.TopNavBar {
    background-color: transparent;
}

body.searchFocus .TopNavBar {
    z-index: auto;
}

#HeaderGroup {
    box-shadow: none;
    z-index: 5910 !important;
}

.FooterGroup,
.dbc {
    max-width: 100%;
}

.searchActive .ToplinksGroup .search #dvSearch form,
#divMobSearch #dvSearch > form {
    float: none;
}

.searchActive .ToplinksGroup .search {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    padding: 30px;
    background-color: #fff;
}

.searchActive .ToplinksGroup .search .dvSearch {
    max-width: 1100px;
    float: none;
    margin: 0 auto;
}

.ToplinksGroup .search .searchDropdownContent {
    display: none;
    padding: 30px 20px 20px 42px;
}

.ToplinksGroup .search .searchDropdownClose {
    display: none;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    top: -25px;
    background-size: 500px 500px;
    background-position: -195px -175px;
    cursor: pointer;
}

.ToplinksGroup .search .searchDropdownClose:hover {
    background-position: -195px -203px;
}

.searchActive .ToplinksGroup .search .searchDropdownContent,
.searchActive .ToplinksGroup .search .searchDropdownClose {
    display: block;
}

.productlist-overlay {
    position: absolute;
    z-index: 5000;
    top: 38px;
}

.searchActive .header-overlay {
    opacity: 0.6;
    visibility: visible;
    top: 101px;
}

.ToplinksGroup .search #cmdSearch span {
    background: none;
    width: 100%;
    height: 100%;
}

#searchSuggestionRenderOverride li,
#MobsearchSuggestionRenderOverride li {
    font-size: 14px;
}

#searchSuggestionRenderOverride li .header,
#MobsearchSuggestionRenderOverride li .header {
    font-weight: var(--fw-bold);
    padding: 15px 0;
    display: inline-block;
}

#searchSuggestionRenderOverride li a,
#MobsearchSuggestionRenderOverride li a {
    float: none;
    display: inline-block;
    cursor: pointer;
    padding-bottom: 5px;
    width: auto;
    height: auto;
}

.dvSearchWrap {
    padding: 0;
}

#MobcmdSearch {
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    padding: 0;
    max-width: none;
    top: 0;
    background-position: -338px -192px;
    background-size: 600px 600px;
    display: none;
}

#MobcmdSearch::before {
    content: none;
}

#ModSearchClose {
    display: none;
    position: absolute;
    top: 20px;
    right: 5px;
    background-size: 500px 500px;
    background-position: -191px -170px;
}

#divMobSearch.open #ModSearchClose {
    display: block;
}

#divMobSearch .searchDropdownContent {
    margin-top: 20px;
}

.IconBagandWish,
.bsheaderIconsWrap {
    float: right;
    height: 100%;
}

.bsheaderIcons #topLinkMenu .TopLink > a,
.bsheaderIcons a.login {
    line-height: 1em;
    padding: 0;
    height: 100%;
    text-align: center;
    background: none;
}

.bsheaderIcons #topLinkMenu .TopLink > a .hidden-xs,
.bsheaderIcons a.login .hidden-xs,
#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyTitle {
    display: block;
    font-size: 1.1em;
    line-height: 1em;
}

.bsheaderIcons #topLinkMenu .TopLink > a .hidden-xs,
.bsheaderIcons a.login .hidden-xs {
    display: none;
}

.bsheaderIcons #topLinkMenu .TopLink > a .ico,
.bsheaderIcons a.login .ico {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-size: 500px 500px;
    background-position: -104px -32px;
    vertical-align: middle;
}

#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyTitle,
#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyIco {
    cursor: pointer;
}

.LanguageCurrency {
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
}

#divAjaxLoaderImage {
    display: none !important;
}

.lillBasket #divBagItems {
    top: 55px;
    right: 0;
    box-shadow: none;
    border: 1px solid #efefef;
}

.lillBasket #divBagItems .bagHeader p {
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: var(--fw-bold);
}

.lillBasket #divBagItems .bagHeader #divAddRemoveToBag {
    display: none !important;
}

.lillBasket #divBagItems .bagHeader #clsBasketMob::before {
    content: none;
}

.lillBasket #divBagItems #divBagItemsChild #ulBag li {
    padding: 11px 0 10px;
}

.lillBasket #divBagItems #divBagItemsChild #ulBag li a.removeClass {
    width: 15px;
    height: 15px;
    padding: 0;
    text-indent: 100%;
    border: none;
    background-size: 300px 300px;
    background-position: -117px -104px;
}

.CheckoutHeader .headerImg a img {
    max-height: 35px;
}

.lillBasket #divBagItems #divButtons #aViewBag span,
.lillBasket #divBagItems #divButtons #aCheckout span {
    position: relative;
}

.lillBasket #divBagItems #divButtons #aCheckout {
    font-size: 14px;
    padding: 20px 10px;
    font-weight: var(--fw-bold);
}

.lillBasket #lblBagSubTotal {
    text-transform: uppercase;
    margin-right: 10px;
}

.PreviousDisable,
.NextDisable {
    background-size: 750px 750px;
    opacity: 0.2;
}

.PreviousEnable,
.NextEnable {
    background-size: 750px 750px;
    opacity: 1;
}

.PreviousEnable,
.PreviousDisable,
.PreviousEnable:hover,
.PreviousDisable:hover {
    background-position: -39px -270px;
}

.NextEnable,
.NextDisable,
.NextEnable:hover,
.NextDisable:hover {
    background-position: -105px -270px;
}

.lillBasket #ulBag li span {
    line-height: 1.4em;
}

.lillBasket #ulBag li span.BaskName {
    font-weight: var(--fw-bold);
    height: 18px;
}

.bsheaderIcons #topLinkMenu .TopLinkDrop {
    position: relative;
    line-height: normal;
    width: 100%;
    float: none;
}

#topLinkMenu .TopLinkDrop .TopSubLinkMenu {
    display: none;
    position: absolute;
    top: 32px;
    left: 50%;
    width: 0;
    background: #fff;
    z-index: 2;
    transform: translateX(-50%);
    border: 0;
    visibility: hidden;
    opacity: 0;
}

#topLinkMenu:hover .TopLinkDrop .TopSubLinkMenu {
    visibility: visible;
    opacity: 1;
    border: 1px solid #f1f1f1;
    width: 200px;
}

.bsheaderIcons #topLinkMenu ul li a {
    padding: 10px 20px;
    background: none;
    text-align: center;
}

.bsheaderIcons #topLinkMenu ul li.SignOut a {
    text-transform: uppercase;
}

.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li {
    float: none;
    width: 100%;
    line-height: normal;
}

.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll,
.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.FirstTopSub {
    margin-top: 5px;
}

#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyIco,
#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyTitle,
#currencyLanguageSelector .spanCurrencyLanguageSelector .flag {
    display: none;
}

#currencyLanguageSelector .spanCurrencyLanguageSelector p {
    float: none;
}

.Responsive .IconBagandWish .WishList,
.Responsive .IconBagandWish #divBag {
    width: 100%;
    font-size: 1em;
}

.Responsive .IconBagandWish #divBag {
    position: static;
    padding: 0;
}

#lblWishList {
    display: none;
}

.storeGroup #topLinkMenu ul.TopLinkMenu li {
    width: 100%;
    float: none;
    line-height: normal;
}

.storeGroup #topLinkMenu ul.TopLinkMenu li a {
    overflow: hidden;
    line-height: 40px;
    padding: 0;
    height: 100%;
    text-align: center;
    background: none;
}

.storeGroup #topLinkMenu ul.TopLinkMenu li a span {
    width: 32px;
    float: none;
    display: inline-block;
    background-position: -369px -6px;
    background-size: 700px 700px;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    text-indent: -9999px;
    text-align: left;
}

.storeGroup #topLinkMenu ul.TopLinkMenu li a:hover span {
    background-position: -369px -48px;
}

.currencyLanguageSlider #divCurrencySelector {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0;
}

#divCurrencySelector span.currencyHeader {
    display: none;
}

.LanguageCurrency .currencyLanguageSlider {
    right: -1px;
    background-color: #fff;
    position: absolute;
    z-index: 5930 !important;
    width: 81px;
    border: 1px solid #efefef;
    padding: 8px 0;
    box-shadow: none;
    top: 75px;
}

.LanguageCurrency .currencyLanguageSlider ul,
.LanguageCurrency .currencyLanguageSlider li {
    display: block;
    width: 100%;
    padding: 0;
}

.LanguageCurrency .currencyLanguageSlider li label {
    font-size: 1em;
    font-weight: var(--fw-normal);
    display: block;
    margin: 0;
    padding: 8px 4px;
    cursor: pointer;
}

.LanguageCurrency .currencyLanguageSlider li.activeHover label {
    font-weight: var(--fw-bold);
}

.currencySelector input[type="radio"] {
    display: inline-block;
}

.IconBagandWish #divBagTotalLink a#aBagLink .bagIcon:hover {
    background-position: -192px -32px;
}

.Responsive .IconBagandWish #divBag #bagTotalContainer #bagTotal {
    font-size: 1.1em;
    margin: 0;
    line-height: 1em;
}

#divBagTotalLink #bagName {
    display: none;
}

.mp-container #divMobSearch .dvSearchWrap {
    padding: 50px 20px 0;
    border: none;
}

#divMobSearch .dvSearch {
    border: 0;
    width: 100%;
}

#divMobSearch #MobtxtSearch {
    width: 100%;
    color: #000;
    padding: 0;
    height: 40px;
    border-bottom: 1px solid #ddd;
    float: none;
}

#divMobSearch #MobtxtSearch:focus {
    text-align: left;
}

.ui-autocomplete li a strong {
    font-size: 1em;
}

.flanProdList #navlist {
    display: flex;
    flex-wrap: wrap;
}

.flanProdList #navlist li {
    padding: 0 5px;
}

@media (max-width: 1021px) {
    #HeaderGroup {
        border-bottom: 1px solid #ccc;
    }

    #BodyWrap.menu-search-shown #divMobSearch {
        top: 55px;
    }

    #divBagTotalLink a#aBagLink #bagQuantityContainer {
        position: relative;
    }
}

.mp-container .mp-menu .mp-level #homeMenu li > a.mp-close,
.mp-container .mp-menu .mp-level ul > li a.mp-close,
.dvSearch .TextBoxClear {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 25px;
    border: none;
    box-shadow: none;
    background-position: -17px -434px;
    background-size: 600px 600px;
    font-size: 11px;
    display: block;
    padding: 0;
}

.mp-container .mp-menu .mp-level ul > li a.mp-close {
    display: none;
}

#divCurrencyLanguageMobile .currencyOption {
    position: relative;
    display: flex;
    align-items: center;
}

#divCurrencyLanguageMobile .currencyOption input {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0;
    width: 20px;
    height: 20px;
}

#divCurrencyLanguageMobile .currencyOption label {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    min-height: 20px;
}

#divCurrencyLanguageMobile .currencyOption label::before,
#divCurrencyLanguageMobile .currencyOption label::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;
    transition:
        0.25s opacity ease,
        0.25s background-color ease,
        0.25s border-color ease;
}

#divCurrencyLanguageMobile .currencyOption label::before {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: #fff;
}

#divCurrencyLanguageMobile .currencyOption label::after {
    top: calc(50% + 3px);
    left: 3px;
    transform: translateY(calc(-50% - 3px));
    width: 14px;
    height: 14px;
    opacity: 0;
    background-color: #000;
}

#divCurrencyLanguageMobile .currencyOption input:checked + label::after {
    opacity: 1;
}

.dvSearch .TextBoxClear {
    height: 35px;
    width: 20px;
    transform: none;
    background-size: 400px 400px;
    background-position: -156px -135px;
    font-family: none;
    right: 0;
    top: 0;
    visibility: hidden;
}

.dvSearch .TextBoxClear::before {
    content: none;
}

#mp-menu {
    height: calc(100% - 55px);
    transition: transform 0.3s ease;
    width: 100%;
    z-index: 10071 !important;
    top: 55px !important;
}

.body-search-open #mp-menu {
    top: 76px !important;
    height: calc(100% - 76px);
}

.body-search-open #BodyWrap {
    height: 100%;
    overflow: hidden;
}

ul#homeMenu {
    display: none;
}

.accordionMenuContentWrap .has-dropdown.open > .am-level > ul {
    display: block !important;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
}

.accordionMenuContentWrap .shop > ul > li.root.open > a,
.accordionMenuContentWrap .has-dropdown li.level1.open > a {
    box-shadow: none;
}

.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
.accordionMenuContentWrap #liMobileCurrencySelector > a::after {
    background-size: 600px 600px;
    background-position: -137px -214px;
    top: 13px;
    right: 15px;
}

.am-menu .am-level a.MobMenChevron.rotate-90::after,
.mp-menu .mp-level a.MobMenChevron.rotate-90::after {
    transform: none;
    background-position: -299px -223px;
}

.am-menu .accordionMenuContentWrap > .mobMenuGroup .AccIconJW a.MobMenChevron::after,
.mp-menu .mp-level .monMenuGroup .AccIconJW a.MobMenChevron::after,
.am-menu .accordionMenuContentWrap > .mobMenuGroup .AccIconJW a.MobMenChevron.rotate-90::after,
.mp-menu .mp-level .monMenuGroup .AccIconJW a.MobMenChevron.rotate-90::after {
    background-size: 350px 350px;
    background-position: -242px -50px;
}

.am-menu .accordionMenuContentWrap > .mobMenuGroup a.MobMenChevron::after,
.mp-menu .mp-level .mobMenuGroup a.MobMenChevron::after,
.accordionMenuContentWrap #liMobileCurrencySelector > a::after {
    background-position: -137px -214px;
    top: 15px;
}

.am-menu .accordionMenuContentWrap > .mobMenuGroup a.MobMenChevron.rotate-90::after,
.mp-menu .mp-level .mobMenuGroup a.MobMenChevron.rotate-90::after,
.accordionMenuContentWrap #liMobileCurrencySelector > a.rotate-90::after {
    background-size: 400px 400px;
    background-position: -347px -318px;
}

.accordionMenuContentWrap .mob-login .MobMenIcon {
    display: none;
}

.accordionMenuContentWrap .has-dropdown > .am-level > ul li a {
    text-transform: none;
}

.shop > ul > li,
.shop > ul > li.mmHasChild .am-level > ul > li,
.shop + ul > li {
    display: list-item !important;
    height: auto !important;
}

.shop > ul > li#liAllDepts,
.shop > ul > li.mmHasChild .am-level > ul > li.deskOnly {
    display: none !important;
}

.am-menu .accordionMenuContentWrap > ul.mobMenuGroup li.root > a,
.accordionMenuContentWrap .mob-login {
    padding: 15px 40px 15px 20px;
}

.am-menu .accordionMenuContentWrap .shop > ul {
    padding-bottom: 20px;
    background-color: #fff;
}

.accordionMenuContentWrap .AccIconJW .MobMenIcon.glyphicon {
    display: none;
}

.accordionMenuContentWrap .shop > ul > li.root > a {
    font-weight: var(--fw-bold);
}

.accordionMenuContentWrap .has-dropdown > .am-level > ul li.sdmColHeader a {
    padding-left: 30px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.MenuCloseActive {
    position: fixed;
    width: 50px;
    height: 45px;
    padding: 0;
    right: -50px;
    top: 51px;
    background-size: 500px 500px;
    background-position: -1px -350px;
    background-color: #fff;
    font-size: 11px;
    cursor: pointer;
    transition: all 0.25s linear 0s;
    z-index: 10071;
}

.body-search-open .MenuCloseActive {
    top: 76px;
}

.PullMenuActive .MenuCloseActive {
    right: 0;
}

.ToplinksGroup #mobSearchContainer {
    display: none;
    width: 50px;
    height: 40px;
    position: absolute;
    background-color: #fff;
    top: 9px;
    right: 15px;
    padding: 0;
}

@media (min-width: 1022px) {
    nav#topMenu > ul li .singlecolumnadvert .singleAdContainer .singleAdImage::before,
    nav#topMenu > ul li .singlecolumnadvert .view-button,
    .emailInput,
    .signupEmailProcessing,
    .signupEmailSuccess,
    #addressbook-list ul li .addressbook-type span::before,
    #addressbook-list ul li .addressbook-type a::before,
    #topMenuWrapper nav#topMenu ul li.root > a::after {
        transition: all 400ms linear 0s;
    }

    .mp-container .mp-menu,
    #divMobSearch.open {
        display: none;
    }

    .HeaderWrap .TopNavBar .ToplinksGroup .bsMenu {
        height: 100%;
    }

    .MenuSearchContainer .bsIconsSearch .bsheaderIcons,
    .MenuSearchContainer .bsIconsSearch .bsheaderIcons #divAccount,
    #topLinkMenu,
    .MenuSearchContainer .bsIconsSearch .bsLanguageCurrency {
        height: 100%;
    }

    #topLinkMenu > ul {
        width: 100%;
        height: 100%;
    }

    .MenuSearchContainer .bsIconsSearch .bsheaderIcons,
    .MenuSearchContainer .bsIconsSearch .bsLanguageCurrency {
        float: right;
    }

    .bsheaderIconsWrap .bsheaderIcons,
    .bsheaderIconsWrap .bsheaderIcons .AccountLink {
        width: 100%;
    }

    .ToplinksGroup .LogoWrap {
        height: auto;
    }

    .bsheaderIcons #topLinkMenu .TopLink > a:hover .ico,
    .bsheaderIcons a.login:hover .ico {
        background-position: -104px -77px;
    }
}

@media (min-width: 1022px) and (max-width: 1300px) {
    .Responsive .bsheaderIconsWrap,
    .MenuSearchContainer .bsIconsSearch .bsLanguageCurrency,
    .MenuSearchContainer .bsIconsSearch .bsheaderIcons {
        width: 60px;
    }
}

@media (min-width: 1301px) {
    .Responsive .bsheaderIconsWrap,
    .MenuSearchContainer .bsIconsSearch .bsLanguageCurrency,
    .MenuSearchContainer .bsIconsSearch .bsheaderIcons {
        width: 80px;
    }
}

@media (max-width: 1021px) {
    #divMobSearch {
        position: relative;
    }
}

@media (max-width: 767px) {
    .MenuSearchContainer {
        width: 31.5%;
    }

    #logoContainer {
        width: 37%;
    }

    .flanProdList .ContentWrapper {
        padding: 0;
    }

    .ui-autocomplete {
        border-left: 0 !important;
        border-right: 0 !important;
        padding: 10px 47px;
        width: 100% !important;
        max-width: none;
        left: 0 !important;
        max-height: calc(100vh - 141px);
    }
}

@media (max-width: 1021px) {
    #BodyWrap.PullMenuActive {
        position: fixed;
        width: 100%;
        height: 100%;
    }

    .HeaderWrap {
        transition: top 0.25s linear 0.4s;
    }

    .HeaderWrap .TopNavBar {
        height: 100%;
    }

    #divMobSearch,
    #divMobSearch.open {
        z-index: 10075;
    }

    .mobSortFilter .mobFilterContainer,
    .mobSortFilter .productFilterTitleBox {
        height: 45px;
    }

    #ProductContainer #TopPaginationWrapper.sticky .paginationWrapper {
        transition: top 0.25s linear 0.4s;
    }

    .LogoWrap img.whiteHeaderLogo {
        display: none;
    }

    #divBagTotalLink a#aBagLink {
        line-height: 50px;
    }

    .searchFocus .ToplinksGroup #mobSearchContainer {
        display: block;
        z-index: 91;
    }

    #mobileSearchTriggerBtn {
        background-color: #fff;
        width: 100%;
        height: 100%;
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .searchFocus .global-icon.global-icon-cross svg {
        width: 16px;
        height: 16px;
    }

    #divMobSearch.open {
        display: block;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
    }

    .LanguageCurrency .currencyLanguageSlider,
    .lillBasket #divBagItems {
        top: 50px;
    }

    .mp-container .mp-menu {
        z-index: 6001 !important;
    }

    .mp-menu .shop .mp-level > ul {
        padding-bottom: 150px;
    }

    .mp-container .mp-menu .mp-level {
        background-color: #fff !important;
        max-height: 100%;
        height: 100%;
        z-index: 2;
    }

    .mp-container .mp-menu .mp-level #homeMenu li > a {
        color: #000 !important;
        background-color: #fff !important;
        text-align: center;
        text-transform: uppercase;
        font-weight: var(--fw-bold);
        font-size: 1.6em;
    }

    .mp-container .mp-menu .mp-level #homeMenu li > a:first-child {
        pointer-events: none;
    }

    .mp-container .mp-menu .mp-level ul > li a.mp-back {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 32px;
        height: 25px;
        border: none;
        box-shadow: none;
        background-position: -180px -211px;
        background-size: 600px 600px;
        font-size: 11px;
        padding: 0;
        display: block;
        background-color: transparent;
    }

    .mp-container .mp-menu .mp-level .MobileMenuContentWrap {
        padding-top: 60px;
        padding-bottom: 20px;
    }

    ul#homeMenu ~ .shop {
        border-top: 1px solid #ccc;
    }

    .mp-back-text,
    #spanCurrencyBack {
        display: none;
    }

    .mp-container .mp-menu .mp-level ul > li a.mp-back::before,
    .mp-container .mp-menu .mp-level ul > li a.mp-back::after {
        content: "";
    }

    .dvSearch .TextBoxClear {
        height: 32px;
        top: 1px;
        right: 10px;
    }

    .mp-container .mp-menu .mp-level .mmHasChild .mp-level .menulevelheader,
    .mp-container .mp-menu .mp-level .currencyHeaderMob,
    .mp-container .mp-menu .mp-level .languageHeaderMob {
        padding: 10px 20px 10px 40px;
        line-height: 25px;
        background: #fff;
        font-size: 13px;
        color: #000;
        font-weight: var(--fw-normal);
        text-align: left;
        text-shadow: none;
    }

    .mp-container .mp-menu .mp-level .mmHasChild .mp-level .menulevelheader a {
        text-decoration: none;
        color: #000 !important;
        display: block;
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: var(--fw-bold);
    }

    .mp-container .mp-menu .mp-level .shop ul > li.mmHasChild > a > .MobMenChevron,
    .mp-container .mp-menu .mp-level .MobileMenuContentWrap > .mobMenuGroup > .has-dropdown > a .MobMenChevron {
        width: 32px;
        height: 25px;
        background-position: -323px -386px;
        background-size: 600px 600px;
        margin-top: 0;
        padding: 0;
    }

    .mp-container .mp-menu .mp-level .shop ul > li.mmHasChild .level2 .menuitemtext {
        text-transform: none;
    }

    .mp-container .mp-menu .mp-level ul > li.mmHasChild a:hover .menuitemtext {
        text-decoration: underline;
    }

    .mp-container .mp-pusher .mp-menu {
        max-width: 100%;
        width: 100%;
    }

    .mp-menu .shop .root > .mp-level > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder1 {
        order: 1;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder2 {
        order: 2;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder3 {
        order: 3;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder4 {
        order: 4;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder5 {
        order: 5;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder6 {
        order: 6;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder7 {
        order: 7;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder8 {
        order: 8;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder9 {
        order: 9;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder10 {
        order: 10;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder11 {
        order: 11;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder12 {
        order: 12;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder13 {
        order: 13;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder14 {
        order: 14;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder15 {
        order: 15;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder16 {
        order: 16;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder17 {
        order: 17;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder18 {
        order: 18;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder19 {
        order: 19;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder20 {
        order: 20;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder21 {
        order: 21;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder22 {
        order: 22;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder23 {
        order: 23;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder24 {
        order: 24;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder25 {
        order: 25;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder26 {
        order: 26;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder27 {
        order: 27;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder28 {
        order: 28;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder29 {
        order: 29;
    }

    .mp-menu .shop .mp-level > ul > li.menuOrder30 {
        order: 30;
    }

    .am-menu ul li > a,
    .mp-menu .mp-level ul li > a,
    .currencySelectorMobile li > .currencyOption {
        box-shadow: none;
    }

    .currencySelectorMobile input[type="radio"] {
        margin: 0;
        vertical-align: middle;
    }

    .currencySelectorMobile label {
        display: inline-block;
        margin: 0 0 0 10px;
        line-height: 1em;
        vertical-align: middle;
    }

    .mp-menu .shop .mp-level > ul > .breakerMenu {
        border-top: 1px solid #aaa;
    }

    .mp-container .mp-menu .mp-level .shop ul li.noMob {
        display: none;
    }

    #liMobileLanguageSelector {
        display: none !important;
    }

    .mp-container .mp-menu .mp-level .shop ul li.mobOnly {
        display: block;
    }

    .mp-container .mp-menu .mp-level .shop ul li > a {
        font-weight: var(--fw-normal);
        font-size: 1.2em;
        background: #fff;
        color: #000;
    }

    .mp-container .mp-menu .mp-level .shop ul li.sdmColStrong > a {
        font-weight: var(--fw-bold);
    }

    .mp-container .mp-menu .mp-level .shop ul li .breakerMenu > a {
        font-size: 1.4em;
    }

    .mp-container .mp-menu .mp-level ul li > a {
        background-color: #fff;
        color: #000;
        padding: 10px 10px 10px 30px;
        line-height: 25px;
        font-weight: var(--fw-normal);
        font-size: 1.2em;
    }

    .mp-container .mp-menu .mp-level ul li.level1 a.menuitemtext {
        padding: 10px 10px 10px 40px;
    }

    .mp-container .mp-menu .mp-level ul li.level2.spaceAll {
        margin-bottom: 10px;
    }

    .mp-container .mp-menu .mp-level ul li.level2 > a {
        padding: 5px 10px;
    }

    .mp-container .mp-menu .mp-level ul li.LoggedInSwitchOn,
    .mp-container .mp-menu.show-account .mp-level ul li.LoggedInSwitchOff {
        display: none;
    }

    .mp-container .mp-menu.show-account .mp-level ul li.LoggedInSwitchOn {
        display: block;
    }

    .mp-container .mp-menu .mp-level ul li > a,
    .mp-container .mp-menu .mp-level ul li > a .menuitemtext {
        width: 100%;
    }

    .mp-container .mp-menu .mp-level ul li > a > span {
        padding: 0 5px;
    }

    .mp-container .mp-menu .mp-level .shop ul li.has-dropdown > a .menuitemtext,
    .mp-container .mp-menu .mp-level ul li.mobLowerMenu > a .menuitemtext,
    .mp-container .mp-menu .mobMenuAccount .mp-level ul li > a .menuitemtext,
    .mp-container .mp-menu .mp-level .mobMenuGroup .has-dropdown a .menuitemtext {
        width: calc(100% - 37px);
    }

    .mp-container .mp-menu .mp-level .shop ul li.has-dropdown > .mp-level ul li .menuitemtext,
    .mp-container .mp-menu .mp-level .mobMenuGroup .has-dropdown .mp-level .mobMenuGroup .menuitemtext {
        text-transform: none;
    }

    .mp-container .mp-menu .mp-level .MobileMenuContentWrap > .mobMenuGroup .has-dropdown a .MobMenChevron {
        background-position: -407px -480px;
    }

    .mp-container .mp-menu .mp-level ul li > a:hover,
    .mp-container .mp-menu .mp-level ul li > a:hover .menuitemtext {
        text-decoration: underline;
    }

    .mp-container .mp-menu #dnn_mobLogin_loginLink .MobMenIcon.glyphicon-chevron-right {
        background: none;
    }

    .mp-container .mp-menu .MobMenIcon.glyphicon::before,
    .mp-container .mp-menu .MobMenIcon.glyphicon::after {
        content: "";
    }

    .mp-container .mp-menu .mobLowerMenu .MobMenIcon.glyphicon::before {
        display: block;
        width: 32px;
        height: 25px;
        background-size: 650px 650px;
    }

    .mp-container .mp-menu .mobMenuAccount .MobMenIcon.glyphicon::before {
        background-position: -204px -89px;
    }

    .mp-container .mp-menu .mobMenuWish .MobMenIcon.glyphicon::before {
        background-position: -68px -89px;
    }

    .mp-container .mp-menu .mobMenuStores .MobMenIcon.glyphicon::before {
        background-position: -342px -87px;
    }

    .mp-container .mp-menu .mp-level.show-level ul li > a .MobMenIcon.glyphicon::before {
        background-position: -339px -132px;
    }

    .mp-container .mp-menu .MobMenIcon.glyphicon {
        float: right;
        background-position: -431px -208px;
        background-size: 800px 800px;
    }

    .mp-container .mp-menu .MobMenIcon.glyphicon.glyphicon-user,
    #homeMenu ~ #homeMenu {
        display: none;
    }

    .mp-container .mp-menu ul li > a.mob-login {
        padding: 10px 15px;
        border-top: 1px solid #fff;
    }

    .mobMenuNewsletter {
        background: #fff;
        padding: 15px;
        z-index: 1;
    }

    .mobMenuNewsletter #menuNewsletter {
        padding: 5px 10px;
        width: 100%;
        margin: 20px auto 15px;
        border: solid 1px #000;
        height: 34px;
        max-width: 500px;
    }

    .mobMenuNewsletter .newsletterButtons {
        width: 100%;
        overflow: hidden;
        margin: 0 auto 15px;
        max-width: 500px;
    }

    .mobMenuNewsletter .newsletterButtons span {
        display: block;
        position: relative;
        float: left;
        width: calc(50% - 5px);
        margin-right: 5px;
    }

    .mobMenuNewsletter .newsletterButtons span + span {
        margin: 0 0 0 5px;
    }

    .mobMenuNewsletter .newsletterButtons span input {
        display: block;
        width: 100%;
        height: 34px;
        border: none;
        font-weight: var(--fw-bold);
        text-transform: uppercase;
    }

    .signupInner {
        text-align: center;
    }

    .newsletterTitleMob {
        font-size: 1.2em;
        color: #000;
        font-weight: var(--fw-bold);
    }

    .newsletterTitleMob span {
        padding-bottom: 2px;
        border-bottom: 1px solid #000;
    }

    .emailWrapper {
        text-align: center;
        padding: 20px 0;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist,
    .mp-container .mp-menu .mp-level ul li#mob-account > a,
    .mp-container .mp-menu .mp-level ul li#mob-stores,
    .mp-container .mp-menu .mp-level ul li#liMobileCurrencySelector > a {
        position: absolute;
        height: 60px;
        line-height: 60px;
        right: 0;
        top: 0;
        width: 25%;
        background-color: #fff;
        text-align: center;
    }

    .mp-container .mp-menu .mp-level ul li#mob-account > a {
        right: 25%;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist {
        right: auto;
        left: 25%;
    }

    .mp-container .mp-menu .mp-level ul li#liMobileCurrencySelector > a {
        left: 0;
        right: auto;
        padding: 0;
        font-size: 1.1em;
        color: #000;
    }

    .mp-container .mp-menu .mp-level ul li#liMobileCurrencySelector > a::after {
        content: none;
    }

    .mp-container .mp-menu .mp-level ul li > a.mob-login {
        margin-top: 20px;
    }

    .mp-container .mp-menu .mp-level ul li > a.mob-login,
    .mp-container .mp-menu .mp-level ul li > a.mob-login > span,
    .mp-container .mp-menu .mp-level ul li#mob-signout,
    .logged-in .mp-container .mp-menu .mp-level ul li#mob-signin {
        display: none;
    }

    .logged-in .mp-container .mp-menu .mp-level ul li#mob-signout {
        display: block;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist > a,
    .mp-container .mp-menu .mp-level ul li#mob-account > a,
    .mp-container .mp-menu .mp-level ul li#mob-stores > a {
        padding: 0;
        line-height: 60px;
        box-shadow: none;
        text-shadow: none;
        text-indent: -9999px;
        cursor: pointer;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist > a .MobMenIcon,
    .mp-container .mp-menu .mp-level ul li#mob-account > a .MobMenIcon,
    .mp-container .mp-menu .mp-level ul li#mob-stores > a .MobMenIcon,
    .mp-container .mp-menu .mp-level ul li#liMobileCurrencySelector > a .MobMenIcon,
    .mp-container .mp-menu .mp-level ul li#liMobileCurrencySelector > a #spanCurrencyMenuItem {
        display: none;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist > a,
    .mp-container .mp-menu .mp-level ul li#mob-stores > a {
        height: 100%;
        background: transparent;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist > a::after,
    .mp-container .mp-menu .mp-level ul li#mob-account > a::after,
    .mp-container .mp-menu .mp-level ul li#mob-stores > a::after {
        left: 50%;
        transform: translate(-50%, -50%);
        right: auto;
        width: 30px;
        top: 50%;
        height: 30px;
        content: "";
        background-repeat: no-repeat;
        background-size: 450px 450px;
        background-position: -335px -303px;
        position: absolute;
        font-size: 11px;
    }

    .mp-container .mp-menu .mp-level ul li#mob-account > a::after {
        background-position: -93px -28px;
    }

    .mp-container .mp-menu .mp-level ul li#mob-wishlist > a::after {
        background-position: -132px -28px;
    }

    .mp-container .mp-menu .mp-level ul li#mob-stores > a::after {
        background-position: -160px -396px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .ToplinksGroup #mobSearchContainer {
        right: 15px;
        top: 8px;
    }
}

@media (max-width: 494px) {
    .MenuSearchContainer {
        width: 39%;
    }
}

@media (max-width: 360px) {
    .MenuSearchContainer {
        width: 44%;
    }
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li > ul {
    width: 25%;
    text-align: center;
    height: 100%;
}

nav#topMenu li.root.sidebarSubMenu .SubMenuWrapper > ul .sdmColHeader:hover {
    cursor: pointer;
}

nav#topMenu li.root.sidebarSubMenu .SubMenuWrapper > ul .sdmColHeader:hover > a {
    text-decoration: underline;
    cursor: pointer;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 {
    text-align: center;
    display: inline-block;
    border: 0;
    height: auto;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > a {
    line-height: 1.2em;
    text-transform: uppercase;
    padding: 10px;
    max-width: none;
    text-align: left;
    float: left;
    width: auto;
    min-width: 191px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul li.boldLink a {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul:hover,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul.open {
    display: block;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul {
    height: 100%;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul {
    padding: 5px 5% !important;
    left: 20%;
    height: auto !important;
    text-align: left;
    width: 60%;
    border-left: 1px solid #ccc;
    top: 0;
    min-height: 400px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul li > ul:hover {
    margin-right: 0;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul > li > ul .level2.sdmColStrong a {
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper ul li.level1:hover > a {
    color: #000 !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper ul li.level1 > a:hover {
    text-decoration: underline;
}

@media (max-width: 1021px) {
    nav #topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul {
        padding-left: 100px;
    }
}

.HoF-browse #BreadcrumbGroup {
    padding: 0 20px;
}

.HoF-browse .ContentWrapper {
    padding-right: 0;
    padding-left: 0;
}

.HoF-browse hr {
    display: none;
}

.HoF-browse .FilterProductsTable {
    padding: 0 25px;
}

.s-breadcrumbs-bar > span ol li a:hover {
    text-decoration: none;
}

.s-maincontent-container.HoF-browse .pagination {
    padding: 0 10px;
    margin: 0;
}

.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-left {
    float: left;
    margin-top: 3px;
}

.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-right {
    float: right;
}

.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-right .layout {
    margin-right: 30px;
}

.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-right .layout,
.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-right .sortbyfilter {
    float: left;
    width: auto;
    border: none;
}

.flanProdList .sortbyfilter .sortOptionsHeader::before {
    content: none;
}

.s-maincontent-container.HoF-browse .paginationWrapper .pag-items-right .sortbyfilter {
    border: 1px solid #eee;
    text-align: center;
}

.flanProdList .s-productthumbbox .s-producttext-price .s-largered,
.flanProdList .s-producttext-price .RefandPrice {
    display: inline-block;
    padding-bottom: 0;
    line-height: 1em;
}

.flanProdList .s-producttext-price .RefandPrice {
    margin-left: 5px;
}

.s-maincontent-container.HoF-browse .paginationWrapper .ViewbyCols {
    display: none;
}

.s-maincontent-container.HoF-browse .paginationWrapper .columnselector li {
    border: none;
    margin-right: 0;
}

.s-maincontent-container.HoF-browse .paginationWrapper .columnselector .colopt5 {
    display: none;
}

.s-maincontent-container.HoF-browse .columnselector li a {
    background-size: 450px 450px;
    float: left;
    height: 29px;
    width: 29px;
}

.HoF-browse .s-productthumbbox .s-producttext-price .s-largered span,
.HoF-browse .paginationWrapper .PrevNextButtons.PageNumber .CurrentPageNumber,
.HoF-browse .paginationWrapper .PrevNextButtons.PageNumber .PageSeperator,
.HoF-browse .paginationWrapper .PrevNextButtons.PageNumber .MaxPageNumber,
.HoF-browse .MobFiltersText,
.HoF-browse #mobFilterControls li span {
    letter-spacing: 0.1em;
}

#mobFilterControls li .textIconWrap {
    text-align: center;
}

.HoF-browse #mobFilterControls li span {
    font-size: 14px;
}

.s-maincontent-container.HoF-browse .columnselector li.colopt3 a {
    background-position: -299px -143px;
}

.s-maincontent-container.HoF-browse .columnselector li.colopt4 a {
    background-position: -342px -107px;
}

.s-maincontent-container.HoF-browse .columnselector li.colopt3.selected a,
.s-maincontent-container.HoF-browse .columnselector li.colopt3 a:hover {
    background-position: -299px -107px;
}

.s-maincontent-container.HoF-browse .columnselector li.colopt4.selected a,
.s-maincontent-container.HoF-browse .columnselector li.colopt4 a:hover {
    background-position: -342px -143px;
}

.s-maincontent-container.HoF-browse .paginationWrapper .ViewPerPage {
    border: none;
}

.s-maincontent-container.HoF-browse .paginationWrapper .ViewPerPage .pppl {
    min-width: 32px;
    height: auto;
    font-weight: var(--fw-normal);
    line-height: 1.8em;
    padding: 5px;
    letter-spacing: 0.1em;
    margin-right: 10px;
    color: #fff;
    float: left;
    background-color: #ccc;
}

.s-maincontent-container.HoF-browse .paginationWrapper .ViewPerPage .pppl.pppl-high {
    margin-right: 0;
}

.s-maincontent-container.HoF-browse .paginationWrapper .ViewPerPage .pppl.selected,
.s-maincontent-container.HoF-browse .paginationWrapper .ViewPerPage .pppl:hover {
    background-color: #0f1a30;
}

.s-maincontent-container.HoF-browse .paginationWrapper .sortbyfilter .sortOptionsContainer {
    padding: 10px;
    text-transform: none;
}

.flanProdList .ContentWrapper {
    max-width: 100%;
    padding: 0 0 10px;
}

.flanProdList .ContentWrapper #FiltersAndProductsWrapper {
    position: relative;
}

.flanProdList .ContentWrapper #FiltersAndProductsWrapper .FilterProductsTable,
.flanProdList .BreadcrumbGroupWrapper,
.flanProdList #lblCategoryCopy {
    position: static;
    max-width: 1420px;
    float: none;
    margin: 0 auto;
}

.flanProdList #lblCategoryCopy {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    #read-more-less {
        left: 0;
        right: 0;
        bottom: 10px;
    }

    .catTemplate .siblingLinks {
        white-space: nowrap;
        overflow-x: auto;
        max-width: 100%;
        margin: auto;
        justify-content: left;
        display: flex;
        flex-wrap: nowrap;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        scrollbar-color: #001e45 #c4c4c4;

        @media (min-width: 768px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        ::-webkit-scrollbar-track,
        ::-webkit-scrollbar-thumb,
        ::-webkit-scrollbar {
            display: none;
        }

        span {
            :first-of-type {
                padding-left: 10px;
            }
            :last-of-type {
                padding-right: 10px;
            }
        }

        a {
            line-height: 14px;
            background-color: #fff;
            letter-spacing: 0;
            padding: 12px 17px;
            display: inline-block;
            color: #001e45;
            border: 1px solid #001e45;
            text-transform: none;
            margin: 0 4px 10px 0;

            @media (min-width: 768px) {
                padding: 12px 40px;
            }

            :hover {
                color: #fff;
                background-color: #101a31;
            }
        }
    }

    .textDesc * {
        display: inline;
        color: #000;
        padding: 0 10px;
    }

    .catDesc {
        position: relative;
        padding-bottom: 16px;
    }
}

.flanProdList .BreadcrumbGroupWrapper {
    padding: 0 8px;
}

.flanProdList .BreadcrumbGroupWrapper #BreadcrumbGroup {
    padding: 10px 0;
}

.flanProdList .BreadcrumbGroupWrapper #BreadcrumbGroup .s-breadcrumbs-container {
    margin: 0;
}

.flanProdList.Browse h1 {
    text-transform: none;
}

.s-maincontent-container.HoF-browse .paginationWrapper .sortbyfilter .sortOptionsHeader span {
    color: #000;
    font-weight: var(--fw-bold);
    font-size: 1em;
}

.flanProdList .ddlSortOptions li span:hover {
    text-decoration: underline;
}

.flanProdList .ddlSortOptionsOpen .ddlSortOptions li span::before {
    content: none;
}

.s-maincontent-container.HoF-browse .paginationWrapper .sortbyfilter .lblSortBy::after {
    content: " - ";
}

.mobCbRow {
    z-index: 5904 !important;
}

body.browse-search .breadcrumb {
    display: block;
}

body.browse-search .s-breadcrumbs-bar ol li {
    display: inline-block;
}

body.browse-search .s-breadcrumbs-bar ol li a,
body.browse-search .s-breadcrumbs-bar ol li.last {
    padding: 0 5px;
}

.flanProdList .s-producttext-top-wrapper a {
    float: left;
    width: calc(100% - 30px);
}

.flanProdList .s-producttext-top-wrapper .hotspotwishlist {
    float: right;
}

.flanProdList .productFilterTitleBox .glyphicon::before {
    background-size: 450px 450px;
    background-position: -168px -285px;
    width: 100%;
    height: 100%;
}

.flanProdList .productFilterTitleBox.FilterClosed .glyphicon::before {
    background-position: -203px -285px;
}

.flanProdList .brandSearchSort #spnSearchIcon {
    width: 32px;
    top: 0;
    right: 0;
    height: 100%;
}

.flanProdList .brandSearchSort #spnSearchIcon::before {
    background-size: 600px 600px;
    background-position: -146px -2px;
}

.flanProdList #showFilterButtonDiv {
    display: none;
}

#FiltersAndProductsWrapper .s-producttext-price .s-largered span {
    font-weight: var(--fw-bold);
    letter-spacing: 0;
}

#FiltersAndProductsWrapper .s-producttext-price .s-smalltext,
.originalprice .originalTicket {
    position: relative;
    text-decoration: none;
}

.FlanProdDet .AltProdDet .pdpPrice {
    letter-spacing: 0;
}

#FiltersAndProductsWrapper .s-producttext-price .s-smalltext::after,
.originalprice .originalTicket::after,
#hotspotModal #hsRefPrice::after {
    border-bottom: 1px solid #757575;
    content: "";
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
}

#hsRefPrice {
    text-decoration: none;
    position: relative;
    width: fit-content;
}

#FiltersAndProductsWrapper .s-producttext-top-wrapper .productdescriptionbrand {
    display: none;
}

.flanProdList .productFilterTitle {
    text-transform: none;
}

.flanProdList .productFilterList .FilterValue {
    width: 40px;
}

.flanProdList .s-productthumbtext {
    text-align: left;
    padding: 0 10px;
}

.Browse .FilterProductsCount {
    text-align: center;
}

.HoF-browse #lblCategoryCopy {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 20px 10px;
}

.pppt {
    line-height: 1.2em;
    font-size: 1.2em;
    vertical-align: top;
    letter-spacing: 0.1em;
}

.pppt span {
    font-size: 1em;
    display: inline-block;
    vertical-align: top;
}

#FiltersAndProductsWrapper .productdescriptionname {
    text-transform: none;
    font-size: 1.2em;
}

.ChildCategoriesListWrapper {
    padding: 20px 0 10px;
    border-bottom: solid 1px #bbb;
}

.ChildCategoriesListWrapper h2 {
    color: #000;
    font-weight: var(--fw-bold);
    padding: 0 10px 10px;
    font-size: 1.2em;
    line-height: 1.2em;
    letter-spacing: 0.1em;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a {
    padding: 5px 15px 5px 10px;
    display: block;
    letter-spacing: 0.1em;
    position: relative;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a::after {
    content: "";
    background-size: 450px 450px;
    background-position: -246px -292px;
    width: 15px;
    height: 15px;
    right: 0;
    position: absolute;
    top: 5px;
    font-size: 11px;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a:hover::after {
    background-position: -246px -292px;
}

.flanProdList.plp-header-shared .BreadcrumbGroupWrapper {
    display: none;
}

.flanProdList.plp-header-shared .ContentWrapper {
    padding: 0 0 10px;
}

.catTemplate.catSwiper .catswiperSection {
    max-width: 1000px;
    margin: 20px auto;
}

@media (min-width: 1022px) {
    .flanProdList #FilterContainer {
        margin: 0;
        padding: 0;
    }

    .flanProdList #ToggleFiltersContainer {
        visibility: visible;
    }

    .flanProdList #ToggleFiltersContainer.DesktopHide {
        visibility: hidden;
    }

    .flanProdList .toggleFilterInner {
        width: 100%;
        max-width: 300px;
        top: 7px;
        left: 0;
        overflow-y: scroll;
    }

    .flanProdList .toggleFilterInner #clrallfltrs {
        padding: 30px 30px 10px;
        float: none;
    }

    .flanProdList .toggleFilterInner #clrallfltrs a {
        width: 100%;
        text-align: center;
        border: 1px solid #0f1a30;
        background-color: #fff;
        color: #000;
        padding: 13px 10px;
    }

    .flanProdList .toggleFilterInner #clrallfltrs a:hover {
        background-color: #0f1a30;
        color: #fff;
    }

    .flanProdList .productFilterList {
        max-height: 100%;
        overflow-y: visible;
    }
}

@media (max-width: 1021px) {
    .HoF-browse #lblCategoryCopy {
        display: block;
    }

    .FlanProdDet #mainDetails .sdPageProductContainer .MoreFromGroup .innerMoreDelivery {
        padding-left: 10px;
        padding-right: 10px;
    }

    .flanProdList .Responsive .productFilterTitle {
        text-transform: none;
        margin: 5px 5px 5px 0;
    }

    .flanProdList .productFilters .productFilterTitle::after {
        position: absolute;
        top: 3px;
        right: 5px;
    }

    .AltProdDet .shareMore {
        padding-right: 5px;
    }

    .ProdDetails .ContentWrapper #BreadcrumbGroup,
    .ProdDetails .ContentWrapper .ContentPane .MoreFromGroup {
        padding: 0 15px;
    }

    .ProdDetails .ContentWrapper #productImages {
        padding: 0 15px 15px;
    }

    .FlanProdDet .AltProdDet #productDetails {
        padding: 25px 25px 0;
    }
}

@media (min-width: 1600px) {
    .Browse .FilterProductsCount::before {
        content: "-";
        padding-right: 5px;
        color: #999;
    }

    .Browse .FilterProductsCount .currentProducts,
    .Browse .FilterProductsCount .countDelimeter {
        display: inline;
    }
}

@media (max-width: 767px) {
    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a {
        padding: 0 10px;
        display: block;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 30px;
    }

    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a span {
        color: #000;
    }

    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a:hover,
    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a:hover span {
        text-decoration: underline;
    }

    .MobileBreadcrumbMenuEnabled #dnn_dnnBreadcrumb_siteMap {
        display: block;
    }

    .MobileBreadcrumbMenuEnabled .currentPageBreadcrumb {
        padding: 5px 10px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 10px;
        font-size: 13px;
    }

    .MobileBreadcrumbMenuEnabled .currentPageBreadcrumb span {
        line-height: 30px;
    }

    .MobileBreadcrumbMenuEnabled .currentPageBreadcrumb::before,
    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a::before {
        content: "";
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        pointer-events: none;
        background-size: 750px 750px;
    }

    .MobileBreadcrumbMenuEnabled .s-breadcrumbs-bar > span ol li a::before {
        background-position: -347px -255px;
    }

    .MobileBreadcrumbMenuEnabled .currentPageBreadcrumb.open::before {
        background-position: -280px -254px;
    }

    .MobileBreadcrumbMenuEnabled .currentPageBreadcrumb::before {
        background-position: -215px -255px;
    }

    .flanProdList #lblCategoryCopy {
        display: block;
        padding: 0 10px;
    }
}

.s-productthumbbox h4 a span:hover {
    text-decoration: underline;
}

.flanProdList .productFilterList .FilterAnchor {
    padding: 6px 30px 6px 15px;
}

#hsAddToBagContainer {
    width: calc(100% - 40px);
    margin-bottom: 0;
}

#hotspotModal .wishlistShortcut {
    display: block;
    height: 50px;
    width: 40px;
    position: absolute;
    right: 5px;
    top: 0;
    background-position: -143px -24px;
    background-size: 500px 500px;
}

#hotspotModal .wishlistShortcut:hover {
    background-position: -143px -69px;
}

@media (min-width: 1022px) and (max-width: 1365px) {
    .SortQtyName span:first-of-type {
        display: block;
    }
}

@media (min-width: 1022px) {
    .HoF-browse .refineByLeftHeader {
        margin: 10px 0 0;
        padding: 0;
    }

    .flanProdList #ProductContainer {
        width: 100%;
    }

    .flanProdList .FilterListItem a.greyOut {
        opacity: 0.5;
    }

    .flanProdList .PageFromTo .pppt span,
    .flanProdList .ddlSortOptions li span {
        letter-spacing: 1px;
    }

    .flanProdList .brandSearchSort input#txtBrandSearch {
        padding-right: 32px;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters > li {
        width: 100%;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters > li .productFilterTitleBox {
        position: relative;
        padding: 10px 30px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters > li .productFilterTitleBox::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        height: 1px;
        background-color: #ccc;
    }

    .flanProdList ul.productFilters > li.filter-categories {
        padding: 0;
    }

    .flanProdList .SelectableFilter::before,
    .flanProdList .SelectedFilter::before {
        content: none;
    }

    ul.productFilters .productFilter .FilterName {
        text-transform: none;
        margin-left: 0;
        padding-left: 15px;
        float: left;
        width: calc(100% - 40px);
        font-size: 12px;
    }

    ul.productFilters .productFilter .FilterName::before {
        display: none;
    }

    ul.productFilters .productFilter a:hover,
    ul.productFilters .productFilter .FilterName:hover {
        text-decoration: underline;
    }

    ul.productFilters .productFilter a.greyOut:hover,
    ul.productFilters .productFilter .greyOut .FilterName:hover,
    .Responsive .FilterListItem a.greyOut:hover * {
        color: #000;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .SelectedFilter {
        font-weight: var(--fw-bold);
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .SelectedFilter::before {
        content: none;
    }

    #FiltersAndProductsWrapper .s-producttext-top-wrapper .productdescriptionbrand,
    #FiltersAndProductsWrapper .s-producttext-price span {
        font-size: 14px;
    }

    #FiltersAndProductsWrapper .s-producttext-top-wrapper a:hover,
    .productdescriptionbrand #FiltersAndProductsWrapper .s-producttext-top-wrapper a:hover,
    .productdescriptionname #FiltersAndProductsWrapper .s-producttext-top-wrapper a:hover {
        text-decoration: none;
    }

    #ToggleFiltersContainer .toggleFilterInner::-webkit-scrollbar,
    .productFilter .productFilterList::-webkit-scrollbar,
    .flanProdList #ToggleFiltersContainer::-webkit-scrollbar {
        width: 5px;
    }

    #ToggleFiltersContainer .toggleFilterInner::-webkit-scrollbar-track,
    .productFilter .productFilterList::-webkit-scrollbar-track,
    .flanProdList #ToggleFiltersContainer::-webkit-scrollbar-track {
        background: #ccc;
    }

    #ToggleFiltersContainer .toggleFilterInner::-webkit-scrollbar-thumb,
    .productFilter .productFilterList::-webkit-scrollbar-thumb,
    .flanProdList #ToggleFiltersContainer::-webkit-scrollbar-thumb {
        background: #0f1a30;
        border-radius: 5px;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 30px;
    }

    .flanProdList #CollapseDiv .productFilterTitle::after {
        position: absolute;
        right: 30px;
        top: 12px;
    }

    body.flanProdList .hotspotbuy.hotspotwishlist {
        display: inline-block !important;
    }

    body.flanProdList .hotspotbuy.hotspotquickbuy {
        position: absolute;
        opacity: 0.75;
        right: 5px;
        bottom: 5px;
        width: calc(100% - 10px);
        background-color: #ccc;
        height: 60px;
    }

    .flanProdList .QuickLookText {
        color: #000;
        text-align: center;
        font-size: 14px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: block;
    }

    #ProductContainer .s-productthumbbox .s-productthumbimage .ProductImageList {
        margin-bottom: 5px;
    }

    #hotspotModal .sizeGuideLink {
        padding: 0 5px;
    }

    #hotspotModal .sizeGuideLink a:hover {
        text-decoration: underline;
    }

    #hotspotModal .promotionLink {
        float: left;
        padding: 0 5px;
    }

    #hotspotModal .promotionLink a:hover {
        text-decoration: underline;
    }

    #hotspotModal div.modal-dialog {
        max-width: 1000px;
    }

    #hotspotModal .hsbottom {
        position: relative;
    }

    #ProductContainer .hotspotbuy .WishIcon {
        display: inline-block;
        float: none;
        width: 25px;
        height: 25px;
        background-position: -151px -35px;
        background-size: 500px 500px;
        vertical-align: middle;
    }

    #ProductContainer .hotspotbuy .WishIcon:hover {
        background-position: -151px -80px;
    }

    .hotspotbuy.hotspotwishlist,
    .hotspotbuy.hotspotquickbuy {
        position: relative;
    }

    #ProductContainer .hotspotbuy .QuickLookIcon {
        background-position: -105px -162px;
        background-size: 500px 500px;
    }

    .PinWrapText .hsColourSelection select::-ms-expand,
    .PinWrapText .hsSizeSelection select::-ms-expand {
        display: none;
    }

    .PinWrapText .hsColourSelection select:focus,
    .PinWrapText .hsSizeeSelection select:focus {
        border-width: 0 0 1px;
        border-color: #000;
        box-shadow: none;
    }

    .PinWrapText .hsColourSelection::after,
    .PinWrapText .hsSizeSelection::after {
        content: "";
        background-position: -273px -380px;
        background-size: 600px 600px;
        display: none;
        height: 35px;
        width: 35px;
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    .PinWrapText .ColourDesc {
        display: none;
    }

    .PinWrapText #hsPrice {
        font-size: 1.3em;
        font-weight: var(--fw-bold);
        padding-right: 0;
    }

    .PinWrapText #hsRefPrice,
    .PinWrapText #hsPrice {
        float: none;
        text-align: left;
    }

    #hotspotModal .PinWrapText h2 {
        padding: 10px 0;
        font-size: 1.2em;
    }

    #hotspotModal .modal-body {
        padding: 0;
        margin: 0;
    }

    #hotspotModal .modal-header {
        position: absolute;
        height: 46px;
    }

    #hotspotModal .modal-header .close {
        width: 30px;
        height: 30px;
        top: 8px;
        right: 8px;
        border: none;
        box-shadow: none;
        background-color: transparent !important;
        background-position: -158px -203px !important;
        background-size: 400px 400px !important;
    }

    .productFilter[id*="PriceFilter"] {
        height: 65px;
        padding: 5px 10px 0 30px;
    }

    .PinWrapText .addToBasketContainer .ImgButWrap {
        display: block;
        height: auto;
    }

    .Basket .elevated-cart {
        padding-top: 20px;
    }

    .addToBasketContainer .ImgButWrap #hsAddToBagWrapper,
    .addToBasketContainer .ImgButWrap span a {
        height: 100%;
        display: block;
        width: 100%;
    }

    .addToBasketContainer .ImgButWrap span a .innerHotSpotLine {
        position: relative;
        display: inline-block;
    }

    .FlanProdDet #piThumbList li a {
        padding: 0;
    }

    .FlanProdDet #piThumbList li img {
        padding: 0;
        max-width: 100%;
        width: 100%;
    }

    #piThumbs .Active {
        opacity: 1;
    }

    .flanProdList .refineByLeftHeader h2 {
        text-transform: none;
        padding: 0 5px;
    }

    .Browse .FilterProductsCount {
        display: inline-block;
        text-align: left;
        padding-left: 10px;
        padding-top: 10px;
    }

    .Browse .FilterProductsCount .currentProducts,
    .Browse .FilterProductsCount .countDelimeter {
        display: none;
    }
}

@media (max-width: 1021px) {
    .Responsive #FilterContainer #innerfiltercontainer {
        display: flex;
        flex-direction: column;
    }

    .ChildCategoriesListWrapper {
        order: 5;
        border-top: 1px solid #bbb;
    }

    .ChildCategoriesListWrapper h2 {
        padding: 0 32px;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li {
        font-size: 1.1em;
        min-height: 28px;
        margin-bottom: 5px;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a {
        padding: 5px 0 5px 10px;
        font-size: 1.1em;
        letter-spacing: 1px;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a::after {
        background-position: -239px -290px;
        width: 32px;
        height: 18px;
        right: 0;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a:hover::after {
        background-position: -239px -290px;
    }

    .HoF-browse .refineByLeftHeader {
        padding: 0;
        margin-top: 0;
        position: static;
    }

    .Browse .searchResultSubMessage {
        padding-bottom: 10px;
    }

    .Browse .FilterProductsCount {
        border-top: 1px solid #ccc;
        padding-top: 10px;
        max-width: 80%;
        margin: 0 auto;
    }

    .HoF-browse .productFilterList .FilterAnchor {
        position: relative;
        letter-spacing: 1px;
    }

    .flanProdList .SelectedFilter {
        margin-left: 0;
    }

    #ProductContainer .hotspotbuy .QuickLookIcon {
        background-position: -105px -162px;
        background-size: 500px 500px;
    }

    .flanProdList .FooterWrap {
        padding-bottom: 40px;
        margin-top: 0;
    }

    .flanProdList .Responsive .MobSortSelector .mobAppliedFilters::before,
    .flanProdList .Responsive .FilteringApplied .mobAppliedFilters::before {
        content: "|";
        margin: 0 9px 0 3px;
    }

    .flanProdList .productFilterList .FilterAnchor .SelectableFilter::after,
    .flanProdList .productFilterList .FilterAnchor .SelectedFilter::after,
    .flanProdList .productFilterList .FilterAnchor .SelectableFilter::before,
    .flanProdList .productFilterList .FilterAnchor .SelectedFilter::before {
        display: inline-block;
        position: absolute;
        width: 12px;
        height: 12px;
        left: 1px;
        top: 1px;
        border-radius: 50%;
        border: 1px solid #999;
    }

    .flanProdList .productFilterList .FilterAnchor .SelectedFilter::after {
        background-color: #000;
    }

    .flanProdList .productFilterList .FilterAnchor .SelectableFilter::before,
    .flanProdList .productFilterList .FilterAnchor .SelectedFilter::before {
        width: 16px;
        height: 16px;
        top: -1px;
        left: -1px;
        border: none;
    }

    .flanProdList .productFilterList .FilterAnchor .SelectedFilter::before {
        border: 1px solid #999;
    }

    .flanProdList .SelectedFilter .FilterName,
    .flanProdList .SelectableFilter .FilterName {
        float: none;
        width: calc(100% - 60px);
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    .mobApplyFilter.clearFiltersOpen {
        width: 66.666666%;
    }

    .mobClearFilters {
        width: 33.333333%;
    }
}

.SliderContain .ui-slider {
    border: none;
    background-color: #ccc;
    height: 4px;
}

.SliderContain .ui-slider-range {
    background-color: #0f1a30;
}

.SliderContain .ui-slider-horizontal .ui-slider-handle {
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.SliderContain .ui-slider-handle::after {
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 100%;
}

.productFilter .productFilterList .brandSearchSort input#txtBrandSearch {
    height: 35px;
    border-color: #000;
}

.paginationWrapper .PrevNextButtons.PageNumber .PrevLink,
.paginationWrapper .PrevNextButtons.PageNumber .NextLink {
    height: 30px;
    width: 30px;
    display: inline-block;
    color: transparent;
    background-size: 550px 550px;
    background-color: transparent;
    border: none;
    letter-spacing: 1px;
    min-width: 30px;
}

.paginationWrapper .PrevNextButtons.PageNumber .PrevLink {
    background-position: -164px -190px;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink {
    background-position: -120px -190px;
}

.paginationWrapper .PrevNextButtons.PageNumber .PrevLink.DisabledLink,
.paginationWrapper .PrevNextButtons.PageNumber .NextLink.DisabledLink {
    background-color: #ccc;
    color: transparent;
    display: none;
}

.paginationWrapper .PrevNextButtons.PageNumber .PrevLink::before,
.paginationWrapper .PrevNextButtons.PageNumber .NextLink::before {
    display: none;
}

.signup-wrapper .signupInner {
    border: none;
}

.footerCopyRightLine {
    text-align: center;
}

.footerAppLine {
    text-align: center;
    padding-bottom: 20px;
}

.footerAppLine span {
    display: inline-block;
    width: 50%;
    max-width: 135px;
    margin: 0;
    height: 40px;
}

.footerAppLine span a {
    display: block;
    height: 100%;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-size: 600px 600px;
}

.footerAppLine span.appleLink a {
    background-position: -460px -15px;
}

.footerAppLine span.appleLink a:hover {
    background-position: -460px -60px;
}

.footerAppLine span.playLink a {
    background-position: -454px -109px;
}

.back-to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    text-decoration: none;
    font-size: 12px;
    width: 50px;
    height: 50px;
    padding: 10px;
    display: none !important;
    z-index: 5900;
    background-size: 1000px 1000px;
    background-position: -307px -720px;
}

.FooterGroupLink {
    width: 100%;
    text-align: center;
}

.FooterGroupLink li {
    line-height: 1.1em;
    padding: 8px;
}

.FooterWrap {
    z-index: 1 !important;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
}

.FooterWrap > .container-fluid {
    padding: 0;
}

.AltFooterSocial span {
    float: left;
    height: 3em;
    width: 35%;
    border-bottom: 1px solid #181816;
}

.AltFooterSocial span.AltFirstLine {
    background-position: 0 0;
}

.AltFooterSocial span.AltSecondLine {
    background-position: 420px 0;
}

.AltFooterSocial img {
    width: 30%;
    text-align: center;
    float: left;
}

.AltFooterGroups li {
    font-size: 1.2em;
    line-height: 1.4em;
}

.FooterSubGroup .FooterHeader {
    padding: 10px;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    border-bottom: none;
}

#infoaccordion .FooterHeader {
    border-bottom: 1px solid #ccc;
}

#infoaccordion .FooterHeader h4 {
    font-weight: var(--fw-normal);
}

.mobileSocialIcons {
    border-top: 1px solid #181816;
    width: 95%;
    padding: 20px 0;
    margin: 0 auto;
}

.FooterGroup1,
.FooterGroup2,
.FooterGroup3 {
    min-height: 0;
    overflow: hidden;
}

.FooterGroup1 {
    height: auto;
}

.mobSocGlyphicon {
    font-size: 1.3em;
    padding-left: 15px;
}

.FootdvSearch {
    padding: 0 0 5px 15px;
}

#FoottxtSearch {
    margin-top: 10px;
}

.FooterGroupLink li a {
    width: auto;
    display: inline-block;
    position: relative;
}

.FooterGroupLink li a::before {
    bottom: -4px;
    left: 0;
}

.emailInput {
    text-align: left;
}

.sub-buttons {
    margin-top: 10px;
}

.lillSignUp {
    position: relative;
}

.lillSignUp .signupEmailSuccess,
.lillSignUp .signupEmailProcessing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0f1a30;
    color: #fff;
    padding: 6px 5px;
    font-size: 1.2em;
    z-index: -1;
}

.lillSignUp .signupEmailProcessing {
    padding-left: 10px;
    background-color: rgba(15, 26, 48, 0.8);
}

.signup-wrapper input#signupEmail {
    color: #fff;
    border: 1px solid #fff;
    padding: 14px 0;
    font-size: 1.2em;
}

.signup-wrapper input#signupEmail:focus {
    border-color: #fff;
}

.SocialWrapperHeader {
    margin-bottom: 5px;
    padding: 10px 0;
    font-size: 1.2em;
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: var(--fw-bold);
    letter-spacing: 1px;
}

.SocialWrapperHeader > p:first-child {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.SocialWrapperHeader > p {
    color: #fff;
    text-transform: none;
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-normal);
}

p.SocialWrapperHeader.hidden-xs,
p.FooterHeader {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-bold);
}

.newsletterPrivacy {
    margin-top: 20px;
}

.newsletterPrivacy p {
    font-size: 1.1em;
}

.newsletterPrivacy p a {
    font-size: 1em;
}

.signup-wrapper .sub-buttons span {
    width: 50%;
    float: left;
    position: relative;
    padding-right: 5px;
}

.signup-wrapper .sub-buttons span + span {
    padding: 0 0 0 5px;
}

.signup-wrapper input.signbut {
    border: 1px solid #fff;
    width: 100%;
    padding: 14px 8px;
    font-size: 1.2em;
}

.footerAppLine #FooterApp {
    padding: 10px;
    text-align: center;
}

.TopHeader > div > a {
    text-transform: uppercase;
}

@media (max-width: 1021px) {
    .SocialWrapperHeader {
        text-align: center;
        margin-bottom: 15px;
        padding: 0;
    }

    .SocialWrapperHeader span {
        display: inline-block;
    }

    .signup-wrapper input#signupEmail,
    .signup-wrapper input.signbut {
        padding: 10px;
    }

    .FooterWrap {
        border-top: none;
    }

    .FooterSubGroup .FooterHeader {
        padding: 15px 0;
        border-bottom: 1px solid;
        text-transform: none;
    }

    .FooterGroup .row {
        margin-left: 0;
        margin-right: 0;
    }

    .emailWrapper .sub-buttons {
        margin-top: 15px;
    }

    .FooterGroup .FooterHeader .glyphicon::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        height: 15px;
        width: 15px;
        margin-left: 6px;
        background-size: 500px 500px;
        background-position: -71px -238px;
    }

    .FooterGroup .FooterHeader .glyphicon-chevron-right::before {
        background-position: -71px -238px;
    }

    .FooterGroup .FooterHeader .glyphicon-chevron-down::before {
        background-position: -26px -238px;
    }
}

@media (max-width: 767px) {
    .emailInput .sub-buttons {
        width: 30%;
    }

    .FooterGroupLink li {
        width: 100%;
    }
}

.FlanProdDet #contentWrapper {
    margin: 30px auto 0;
}

.flanProdList .brandSearchSort #spnClearBrandFilter {
    font-size: 1.2em;
    line-height: 1.8em;
}

.s-breadcrumbs-bar .hereTItle,
.sizeVariantHighlight::before,
.variantHighlight::before,
.s-productthumb-extra {
    display: none;
}

.FlanProdDet .productDescription .infoPage .infoTabPage span,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span a {
    color: #000;
    letter-spacing: normal;
}

.FlanProdDet .addToBasketContainer .ImgButWrap a span {
    letter-spacing: 0.1em;
}

.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-right::before,
.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-left::before,
.FlanProdDet .mainImageButtonPrev span::before,
.FlanProdDet .mainImageButtonNext span::before,
.FlanProdDet .pdpWishListIco,
.FlanProdDet .pd-accordion .acc-title h3::after,
#divShareButton .ShareIcons,
.ps-swiper-button-next.swiper-button-next,
.ps-swiper-button-prev.swiper-button-prev,
.rv-swiper-button-next.swiper-button-next,
.rv-swiper-button-prev.swiper-button-prev {
    background-size: 900px 900px;
}

.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-left::before {
    background-position: -278px -320px;
}

.FlanProdDet .AltProdDet #piThumbs .glyphicon-circle-arrow-right::before {
    background-position: -206px -320px;
}

.FlanProdDet .mainImageButtonPrev span::before {
    background-position: -278px -320px;
}

.FlanProdDet .mainImageButtonNext span::before {
    background-position: -206px -320px;
}

.FlanProdDet .mainImageButtons .zoomButton .glyphicon,
.FlanProdDet .mainImageButtonPrev,
.FlanProdDet .mainImageButtonNext {
    background-color: transparent;
}

.FlanProdDet .mainImageButtons .zoomButton .glyphicon::before {
    background-position: -227px -311px;
    background-size: 500px 500px;
}

.FlanProdDet .title h1 .brandTitle {
    display: none;
}

.AltProdDet .pdpPriceRating {
    text-align: left;
}

.AltProdDet #availableFromContainer {
    text-align: center;
}

.FlanProdDet .title h1 #lblProductName {
    font-size: 20px;
    text-transform: none;
    line-height: 1.2em;
}

.ProdDetails .member-price + .pdpPriceRating {
    padding-bottom: 28px;
}

.FlanProdDet .pdpPriceRating {
    margin: 5px 0 0;
}

.FlanProdDet .pdpPriceRating .spnFrom {
    padding-right: 5px;
}

.FlanProdDet .AltProdDet .pdpPrice,
.FlanProdDet .originalprice {
    font-weight: var(--fw-bold);
    margin: 0;
}

.FlanProdDet .headerProductCode,
.FlanProdDet #productDetails select,
.FlanProdDet #MoreFromLinks li.MoreFromLinksRow a::before {
    display: none;
}

.FlanProdDet .RecentlyViewedListOnProductsPage .AltStratBrandDesc:hover {
    text-decoration: underline;
}

.FlanProdDet #divColour .s-productextras-column-1,
.FlanProdDet #divColour .s-productextras-column-2-3 {
    display: inline-block;
    float: none;
    padding: 0;
}

.FlanProdDet .productVariantContainer #divColour::after,
.FlanProdDet .productVariantContainer .swapSize::after {
    content: none;
}

.FlanProdDet #SizeSelectionModal #SizeModalDropDownWrapper {
    position: relative;
}

.FlanProdDet .productVariantContainer #divColour::after,
.FlanProdDet .productVariantContainer .swapSize::after,
.FlanProdDet #SizeSelectionModal #SizeModalDropDownWrapper::after {
    bottom: auto;
    top: 0;
}

.FlanProdDet #SizeSelectionModal #SizeModalDropDownWrapper::after {
    background-size: 900px 900px;
    background-position: -288px -184px;
}

.FlanProdDet #SizeSelectionModal #SizeModalDropDownWrapper.active::after {
    background-position: -351px -184px;
}

.FlanProdDet .ColnSize {
    padding-top: 70px;
}

.FlanProdDet #divColour {
    display: block;
    width: 100%;
    margin: 0 0 10px;
    text-align: left;
    font-size: 1em;
}

.FlanProdDet .s-productextras-checkbox-column-1 label,
.FlanProdDet .s-productextras-column-1 label,
.FlanProdDet .s-productextras-column-1 span,
.FlanProdDet .s-productextras-column-2-3 span {
    font-size: 14px;
}

.FlanProdDet #divColour #BuyColourText {
    text-transform: none;
    font-size: 14px;
}

#divColour .s-productextras-column-2-3 span {
    font-style: normal;
    font-size: 14px;
    margin-left: 5px;
}

.FlanProdDet .productVariantContainer #divColour::after {
    display: none;
}

.FlanProdDet .ColourImagesWrap {
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 30px;
}

.FlanProdDet .ColourImagesWrap ul.colourImages,
.FlanProdDet ul.sizeButtons {
    margin: 0;
}

.FlanProdDet .ColourImagesWrap ul.colourImages::before,
.FlanProdDet .ColourImagesWrap ul.colourImages::after,
.FlanProdDet ul.sizeButtons::before,
.FlanProdDet ul.sizeButtons::after {
    content: none;
}

.FlanProdDet .ColourImagesWrap .colourImages li {
    float: none;
    display: inline-block;
    margin: 0 7px 7px 0;
}

.FlanProdDet .ColourImagesWrap .colourImages li a {
    border: none;
    padding: 0;
}

.FlanProdDet .ColourImagesWrap .colourImages li a img {
    border: 2.5px solid #fff;
    width: 100%;
    max-width: 100px;
}

.FlanProdDet .ColourImagesWrap .colourImages li[aria-checked="true"] a img {
    border: 2.5px solid #000;
}

.FlanProdDet #productVariantAndPrice .swapSize {
    display: block;
    width: 100%;
    order: 2;
    position: relative;
    top: 22px;
}

.FlanProdDet .swapSize .s-productextras-column-1,
.FlanProdDet .swapSize .s-productextras-column-1 label {
    display: block;
    text-align: left;
    width: 100%;
    text-transform: none;
}

.FlanProdDet .swapSize .s-productextras-column-1 {
    border-bottom: 1px solid #808080;
    font-size: 12px;
    padding-bottom: 15px;
}

.FlanProdDet .swapSize .s-productextras-column-2 {
    padding: 25px 0;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul {
    text-align: left;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul li {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px 10px 0;
    height: 60px;
    width: 60px;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul li a {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: var(--fw-bold);
    line-height: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul li a:hover {
    border: 1px solid #999;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul li.greyOut a {
    background: #ccc;
    color: #fff;
    opacity: 1;
    border: 1px solid #ccc;
}

.FlanProdDet .swapSize .s-productextras-column-2 ul li.sizeVariantHighlight a {
    background: #0f1a30;
    color: #fff;
}

.FlanProdDet #productVariantAndPrice .s-productextras-column-3 {
    width: 100%;
    margin-top: 20px;
    text-transform: capitalize;
    text-decoration: underline;
    float: left;
    order: 1;
    text-align: right;
    position: absolute;
    font-size: 14px;
    z-index: 1;
}

.FlanProdDet #productVariantAndPrice .s-productextras-column-3 a {
    padding: 5px;
}

.FlanProdDet #productVariantAndPrice .s-productextras-column-3 .SizeGuideText {
    font-size: 12px;
    border-bottom: none;
    padding: 0;
}

.FlanProdDet .addToBasketContainer .ImgButWrap a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 1px solid #0f1a30;
    padding: 16px 20px;
    min-height: 45px;
    border-radius: 0;
    letter-spacing: 0.1em;
}

.FlanProdDet .addToBasketContainer .ImgButWrap a span::before {
    content: none;
}

.FlanProdDet .addToBasketContainer .ImgButWrap a,
.FlanProdDet .WishListContain a {
    text-shadow: none;
}

.FlanProdDet .WishListContain .sAddToWishListWrapper a > .pdpWishListIco {
    height: 40px;
    width: 30px;
    background-size: 550px 550px;
    display: inline-block;
    background-position: -165px -39px;
}

.FlanProdDet .WishListContain .sAddToWishListWrapper a:hover > .pdpWishListIco {
    opacity: 1;
    background-position: -165px -89px;
}

.FlanProdDet .mainImageButtons #Popup360ButtonWrapper,
.FlanProdDet .mainImageButtons .videoButton {
    display: none !important;
}

.FlanProdDet #productDetails .productVariantContainer #shareThis {
    display: none;
    text-align: center;
    margin: 20px 0;
}

.FlanProdDet #divShareButton {
    display: inline-block;
    float: none;
    padding: 0;
    width: auto;
}

.FlanProdDet #shareThis {
    margin-top: 0;
}

#shareThis .acc-title h3 span {
    display: none;
}

.FlanProdDet #divShareButton .ShareIcons {
    margin: 0 10px;
    background-size: 500px 500px;
    opacity: 0.8;
}

.FlanProdDet #divShareButton .TwitterIcon {
    background-position: -96px -120px;
}

.FlanProdDet #divShareButton .FacebookIcon {
    background-position: -50px -120px;
}

.FlanProdDet #divShareButton .PinterestIcon {
    background-position: -146px -120px;
}

.FlanProdDet #divShareButton .GoogleIcon {
    background-position: -2px -120px;
}

.FlanProdDet #divShareButton .ShareIcons:hover {
    opacity: 1;
}

.PSPlacementTitle {
    background-color: transparent;
}

.AltProdDet #MoreFromLinks {
    border: solid 1px #ccc;
}

.FlanProdDet .PSPlacementHorizontal .PSProdPrice .PSSellPrice,
.FlanProdDet .PSPlacementHorizontal span.PSProdBrand,
.FlanProdDet .RecentlyViewedListOnProductsPage .AltStratRefSell .AltStratSellPrice {
    font-weight: var(--fw-bold);
}

.FlanProdDet .PSPlacementHorizontal span.PSProdTitle,
.FlanProdDet .RecentlyViewedListOnProductsPage span.AltStratDescription {
    text-transform: none;
    padding-top: 10px;
}

.FlanProdDet #productContainerTop {
    padding: 0 0 40px;
}

.productDescReturnsInner {
    border-top: 1px solid #ccc;
    padding: 30px 0;
}

.productDescReturnsInner > div {
    padding: 0 20px;
}

.FlanProdDet .infoTabs {
    display: none;
}

.FlanProdDet .pd-accordion .acc-content,
.FlanProdDet .pd-accordion .acc-content p,
.FlanProdDet .pd-accordion .acc-content p a,
.FlanProdDet .pd-accordion .acc-content ul,
.FlanProdDet .pd-accordion .acc-content ul li {
    color: #000;
}

.FlanProdDet .pd-accordion .acc-content .infoTabs h4 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.FlanProdDet .pd-accordion .acc-content .infoTabs ul > h4,
.FlanProdDet .pd-accordion .acc-content .infoTabs ul + h4 {
    padding-top: 20px;
}

.productDescReturnsInner .deliveryInfo > div {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}

.productDescReturnsInner .deliveryInfo > div:last-of-type {
    border-bottom: 0;
    margin-bottom: 20px;
}

.productDescReturnsInner .deliveryTitle {
    font-size: 1.1em;
    font-weight: var(--fw-bold);
    padding-bottom: 5px;
}

.productDescReturnsInner .deliveryTime {
    padding-bottom: 5px;
}

.productDescReturnsInner .deliveryDesc {
    padding-bottom: 5px;
    font-size: 0.9em;
}

.productDescReturnsInner .deliverPrice {
    font-size: 1.2em;
    padding-bottom: 10px;
    font-weight: var(--fw-bold);
}

.productDescReturnsInner .bonusContent a {
    text-decoration: underline;
}

#productDescReturns .acc-title {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    position: relative;
}

#parDeliveryMethods ul {
    border: none;
    align-items: center;
    padding: 0;
}

#parDeliveryMethods li {
    width: 100%;
    padding: 0;
}

.FlanProdDet .sdtabBody {
    border-bottom: none;
    padding: 0;
}

.FlanProdDet .pd-accordion .acc-content,
#parDeliveryMethods li p.price {
    font-size: 1.1em;
}

.FlanProdDet .pd-accordion .acc-content .descriptionProductCode,
.FlanProdDet .pd-accordion .acc-content .returnsInfo,
.FlanProdDet .pd-accordion .acc-content p,
.FlanProdDet .pd-accordion .acc-content p a,
.FlanProdDet .pd-accordion .acc-content ul,
.FlanProdDet .pd-accordion .acc-content ul li {
    color: #000;
    letter-spacing: normal;
}

.FlanProdDet .pd-accordion .acc-content p a,
.FlanProdDet .productDescription .infoPage .infoTabPage .PreDate {
    font-size: 1em !important;
}

.FlanProdDet .productDescription .infoPage .infoTabPage span,
.FlanProdDet .productDescription .infoPage .infoTabPage span a,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span,
.FlanProdDet .productDescription .infoaccordion .panel-collapse span a,
.FlanProdDet .pd-accordion .acc-content ul li,
#parDeliveryMethods li span.TitleDel,
.uscDetails #parDeliveryMethods .TitleReturns {
    font-size: 1em;
}

.FlanProdDet .RecentlyViewedListOnProductsPage span.AltStratBrand {
    display: none;
}

.FlanProdDet .RecentlyViewedListOnProductsPage .AltStratRefSell .AltStratSellPrice {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
}

.FlanProdDet .PSPlacementTitle,
.FlanProdDet .AltStratTitle {
    font-size: 1.4em;
    text-transform: uppercase;
    background: none;
}

.FlanProdDet .RvStratImgQuick {
    height: auto;
}

.FlanProdDet .AltVertOne {
    border: none;
}

.FlanProdDet .deliveryReturns #parDeliveryMethods li span.TitleDel,
.FlanProdDet .deliveryReturns #parDeliveryMethods li p,
.FlanProdDet .deliveryReturns #parDeliveryMethods li p.price {
    font-weight: var(--fw-normal);
}

.FlanProdDet .deliveryReturns #parDeliveryMethods li p.price {
    margin-top: 0;
}

.FlanProdDet .deliveryReturns #parDeliveryMethods li {
    margin-bottom: 15px;
    padding: 0 15px;
}

.FlanProdDet .deliveryReturns .returnsInfo ul {
    width: 100%;
}

.FlanProdDet .deliveryReturns .returnsInfo ul li {
    list-style: none;
    width: 100%;
    margin: 0 0 10px;
    padding: 0 15px;
}

.FlanProdDet #productDetails {
    padding: 60px 70px;
}

.FlanProdDet #productVariantAndPrice {
    display: flex;
    flex-direction: column;
}

.FlanProdDet .AltProdDet .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.FlanProdDet .BasketWishContainer .addToBasketContainer {
    width: 100%;
    float: none;
}

.AltProdDet .infoaccordion .panel-title {
    font-size: 14px;
}

#productDescReturns .infoaccordion .InfoTab0Container .panel-body,
#productDescReturns .infoaccordion .InfoTab1Container .panel-body,
#productDescReturns .infoaccordion .InfoTab2Container .panel-body {
    font-size: 14px;
    padding: 15px 0;
}

#productDescReturns .infoaccordion .panel-body p {
    font-size: 1em;
}

#productDescReturns {
    margin-top: 10px;
    padding: 0;
}

#productDescReturns .infoaccordion {
    padding-left: 0;
    padding-right: 0;
}

#productDescReturns .infoaccordion .InfoTab0Container .panel-heading,
#productDescReturns .infoaccordion .InfoTab1Container .panel-heading,
#productDescReturns .infoaccordion .InfoTab2Container .panel-heading {
    border-bottom: 1.5px solid #808080;
    padding: 20px 0;
}

@media (min-width: 1022px) {
    .FlanProdDet .AltProdDet #productImages #productImageContainer {
        width: 100%;
        max-width: 100%;
        float: left;
    }

    .FlanProdDet .AltProdDet #productImages .pdpThumbs {
        width: 100%;
        max-width: 100%;
        margin-top: 5px;
        overflow: visible;
    }

    .FlanProdDet .AltProdDet #productImages .pdpThumbs #piThumbList {
        white-space: nowrap;
    }

    .FlanProdDet .AltProdDet #productImages .pdpThumbs #piThumbList li {
        width: 25%;
        padding: 0 3px;
    }

    .FlanProdDet #piThumbs #divPrevImg,
    .FlanProdDet #piThumbs #divNextImg {
        position: absolute;
        width: 30px;
        padding: 0;
        top: 0;
        bottom: 0;
    }

    .FlanProdDet #piThumbs #divPrevImg {
        left: -30px;
    }

    .FlanProdDet #piThumbs #divNextImg {
        right: -30px;
    }

    .FlanProdDet #piThumbs > div > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .MenuSearchContainer .bsIconsSearch {
        margin: 0;
        height: 100%;
    }

    .MenuSearchContainer .bsheaderIconsWrap {
        border-left: 1px solid #fff;
    }

    .FlanProdDet .infoTabPage > span {
        padding-right: 10px;
        display: block;
    }

    #productDescReturns .acc-title {
        margin-bottom: 10px;
    }

    .FlanProdDet .ColourImagesWrap .colourImages li a:hover img {
        border: 2.5px solid #000;
    }

    .signup-wrapper input#signupEmail,
    .signup-wrapper input#signupEmail:focus {
        border-color: transparent;
        border-bottom-color: #fff;
    }

    nav#topMenu ul li.root .SubMenuWrapper > ul > li.Right .newImages .postTitle {
        font-size: 1.25em;
        padding: 15px 0 0;
    }
}

@media (max-width: 1021px) {
    .FlanProdDet .ThumbProdWrap #piThumbList li a {
        padding: 6px;
    }

    .FlanProdDet #piThumbList li .imgdot {
        height: 10px;
        width: 10px;
        background-color: #ccc;
        border: 1px solid #ccc;
    }

    .FlanProdDet #piThumbList li .imgdot.piActiveDot {
        background-color: #0f1a30 !important;
        border: 1px solid #0f1a30;
    }

    .FlanProdDet .pd-accordion .acc-title h3 span.title-text {
        font-weight: var(--fw-bold);
        font-size: 1.334em;
    }

    .FlanProdDet #MoreFromLinks .MoreFromLinksHeader {
        font-size: 1.6em;
    }

    .FlanProdDet .pd-accordion.open .acc-title h3::after {
        background-position: -430px -513px;
    }

    .FlanProdDet .pd-accordion .acc-title h3 {
        padding: 15px 10px;
    }

    .FlanProdDet .pd-accordion .acc-title h3::after {
        background-position: -500px -516px;
    }

    .FlanProdDet .pd-accordion .acc-title h3 span.title-text::before {
        display: none;
    }

    .FlanProdDet .pd-accordion.open .acc-title h3 span.title-text {
        text-decoration: underline;
    }

    .FlanProdDet .pd-accordion .acc-content,
    .FlanProdDet .pd-accordion .acc-content p,
    .FlanProdDet .pd-accordion .acc-content p a,
    .FlanProdDet .pd-accordion .acc-content ul li {
        color: #000;
    }

    .FlanProdDet .pd-accordion {
        border-bottom: 1px solid #ccc;
    }

    .FlanProdDet .AltProdDet .infoTabs.hidden-xs {
        display: block !important;
    }

    .FlanProdDet .productDescription .pd-accordion .infoaccordion {
        display: none;
    }

    #productDescReturns {
        margin-top: 10px;
        padding-left: 0;
    }

    #productDescReturns .infoaccordion {
        padding-left: 0;
    }

    #productDescReturns .infoaccordion .InfoTab0Container .panel-heading,
    #productDescReturns .infoaccordion .InfoTab1Container .panel-heading,
    #productDescReturns .infoaccordion .InfoTab2Container .panel-heading {
        padding: 15px 0;
        font-size: 1.2em;
    }

    .productDescReturnsInner,
    .productDescReturnsInner > div {
        padding: 0;
    }

    .ps-swiper-button-next.swiper-button-next,
    .ps-swiper-button-prev.swiper-button-prev,
    .rv-swiper-button-next.swiper-button-next,
    .rv-swiper-button-prev.swiper-button-prev {
        display: block;
    }

    .ps-swiper-button-next.swiper-button-next,
    .rv-swiper-button-next.swiper-button-next {
        background-position: -478px -178px;
        right: 0;
    }

    .ps-swiper-button-prev.swiper-button-prev,
    .rv-swiper-button-prev.swiper-button-prev {
        background-position: -415px -178px;
        left: 0;
    }

    .FlanProdDet .swiper-container {
        overflow: hidden;
    }

    .FlanProdDet .BasketWishContainer .WishListContain {
        background-color: transparent;
    }

    .FlanProdDet .WishListContain a {
        padding: 0;
    }

    .FlanProdDet .WishListContain .sAddToWishListWrapper a > .pdpWishListIco {
        width: 25px;
        height: 25px;
        background-size: 400px 400px;
        background-position: -118px -27px;
    }

    .FlanProdDet .WishListContain .sAddToWishListWrapper a:hover > .pdpWishListIco {
        background-position: -118px -63px;
    }

    .FlanProdDet .mobileWishList {
        right: 20px;
    }

    #FiltersAndProductsWrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .FlanProdDet .BasketWishContainer .addToBasketContainer {
        width: 100%;
    }

    .FlanProdDet .title h1 span {
        font-size: 13px;
    }

    .FlanProdDet .title h1 #lblProductName {
        font-size: 19px;
    }

    .FlanProdDet .AltProdDet .pdpPrice span {
        font-size: 20px;
    }

    .FlanProdDet .RadTabStrip {
        display: none;
    }

    .FlanProdDet .title {
        padding: 0;
    }

    .FlanProdDet .AltProdDet #productDetails .BasketWishContainer {
        margin: 20px 0 0;
    }

    .AltProdDet .infoaccordion .panel-title {
        font-size: 14px;
    }

    .FlanProdDet .AltProdDet .title {
        text-align: start;
    }

    .FlanProdDet .swapSize .s-productextras-column-2 .sizeButtons li a {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .FlanProdDet .swapSize .s-productextras-column-2 .sizeButtons li span {
        line-height: 1.4em;
    }

    .FlanProdDet .swapSize .s-productextras-column-2 .sizeButtons li {
        font-size: 13px;
        height: 50px;
        width: 50px;
        min-width: 0;
        float: left;
    }

    #productDescReturns .infoaccordion .InfoTab0Container .panel-body p,
    #productDescReturns .infoaccordion .InfoTab1Container .panel-body p,
    #productDescReturns .infoaccordion .InfoTab2Container .panel-body p {
        font-size: 14px;
    }

    .FlanProdDet #divColourImages {
        margin-bottom: 0;
    }

    .FlanProdDet #divColour {
        margin: 20px 0 10px;
    }

    .FlanProdDet .emailWrapper {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .FlanProdDet .pinch-zoom-container {
        min-height: 800px;
    }
}

@media (max-width: 767px) {
    .ProdDetails .ContentWrapper {
        padding: 10px 0;
    }

    .FlanProdDet #MoreFromLinks li.MoreFromLinksRow {
        display: block;
    }

    .FlanProdDet #MoreFromLinks li.MoreFromLinksRow::before {
        display: none;
    }

    .FlanProdDet .pinch-zoom-container {
        min-height: 335px;
    }
}

body.flanProdList .SortQtyName {
    display: block !important;
    margin: 15px 0;
}

.selectedFilters li.selectedFilter > a {
    display: block;
    width: 100%;
    position: relative;
}

.selectedFilters li.selectedFilter > a::before {
    content: "";
    background-position: -313px -293px !important;
    background-size: 450px 450px !important;
    height: 15px;
    width: 15px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.globalSelectedFiltersWrapper {
    min-height: 0;
}

.globalSelectedFilters .SelectedFiltersLabel {
    width: 18%;
    float: left;
    padding: 5px 10px 5px 30px;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    font-weight: var(--fw-bold);
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters {
    padding: 6px 0 0;
    overflow: hidden;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    margin-bottom: 20px;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul {
    position: relative;
    float: left;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li {
    display: inline-block;
    padding-bottom: 6px;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a {
    padding: 5px 15px;
    border-right: 1px solid #000;
    cursor: pointer;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a::before {
    top: 5px;
    left: 15px;
    background-size: 350px 350px !important;
    background-position: -43px -253px !important;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel {
    text-transform: none;
    padding-left: 20px;
    letter-spacing: 1px;
    font-weight: var(--fw-bold);
    line-height: 16px;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel:hover {
    text-decoration: none;
}

.flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul li a .selectedFilterLabel::before {
    display: none;
}

@media (min-width: 1022px) {
    .flanProdList .toggleFilterInner {
        max-width: 330px;
    }

    .flanProdList .toggleFilterInner .productFilters {
        border: none;
    }

    .flanProdList .Responsive #pnl-apri {
        padding: 6px 30px 15px;
    }

    .globalSelectedFilters .SelectedFiltersLabel {
        display: block;
    }

    .flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul::before {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        top: 0;
        left: 0;
        bottom: 6px;
        background-color: #000;
    }

    .globalSelectedFilters .SelectedFiltersContainer {
        width: 82%;
        float: left;
    }

    .flanProdList .DesktopHide .toggleFilterInner,
    .flanProdList .toggleFilterInner {
        top: 44px;
        left: 0;
    }

    .flanProdList .ddlSortOptions {
        right: 0;
        top: 0;
    }

    .productlist-overlay {
        top: 44px;
    }

    #fixed-filters {
        border-bottom: 1px solid #ccc;
    }

    .flanProdList .fixed-filters-on {
        top: 115px;
        padding: 0;
    }

    .flanProdList .fixed-filters-on #FilterContainer {
        max-width: 1410px;
    }

    .flanProdList .fixed-filters-on #innerfiltercontainer {
        position: static !important;
    }

    .flanProdList #innerfiltercontainer,
    .flanProdList .fixed-filters-on #innerfiltercontainer {
        width: 100% !important;
    }

    .flanProdList .fixed-filters-on .refineByLeftHeader h2 .FiltersText,
    .flanProdList .fixed-filters-on .refineByLeftHeader .sortbyfilter .sortOptionsHeader {
        margin: 0;
    }

    #fixed-filters:not(.fixed-filters-on) #innerfiltercontainer {
        top: 0 !important;
    }

    .flanProdList .sortbyfilter .sortOptionsContainer {
        text-transform: none;
        padding: 0;
    }

    .flanProdList .sortOptionsHeader .lblSortBy,
    .flanProdList .refineByLeftHeader h2 .FiltersText {
        padding: 13px;
    }
}

@media (max-width: 1021px) {
    .HoF-browse .MobSortSelector .productFilterTitle:hover {
        text-decoration: underline;
    }

    .HoF-browse .MobSortSelector .productFilterTitle::before {
        display: none !important;
    }

    .HoF-browse #FilterContainer {
        max-height: calc(100% - 132px);
    }

    .HoF-browse .productFilterTitle::before {
        display: none !important;
    }

    .HoF-browse #mobControlBar,
    .HoF-browse #mobFilterControls {
        background: #0f1a30 !important;
    }

    .HoF-browse #mobControlBar #filterByMob .mobFilterAppIcon::before {
        background-size: 600px 600px;
        background-position: -455px -391px;
    }

    .HoF-browse .paginationWrapper {
        margin: 0;
    }

    .HoF-browse .productFilterTitleBox {
        margin: 0 !important;
        padding: 5px 0 5px 10px;
    }

    .HoF-browse .productFilterTitleBox .glyphicon {
        padding: 0;
        width: 32px;
        height: 27px;
    }

    #mobclsfltrs .glyphicon::before,
    #mobclsfltrs .glyphicon::after {
        display: none !important;
    }

    .HoF-browse .Responsive .s-left-header h2.FiltersTitle {
        text-align: center;
        font-size: 1.3em;
        padding: 0 32px;
        display: inline-block;
        width: 100%;
        height: 32px;
        line-height: 32px;
    }

    .HoF-browse #FilterContainer .FilterProductsCount {
        display: none;
    }

    .flanProdList .Responsive .mobDdClose {
        border: none !important;
        background-position: -70px -476px !important;
        background-size: 600px 600px;
        width: 32px;
        padding: 0;
        height: 32px;
        right: 10px;
        top: 10px;
    }

    .HoF-browse .SelectedFilter a,
    .HoF-browse .SelectedFilter span {
        font-weight: var(--fw-bold) !important;
    }

    .HoF-browse .SelectedFilter::before {
        content: none;
    }

    .HoF-browse .SelectableFilter span,
    .HoF-browse .SelectedFilter span {
        padding-left: 10px;
    }

    .HoF-browse .SelectedFilter span::before,
    .HoF-browse .SelectableFilter span::before {
        display: none !important;
    }

    .HoF-browse .productFilterList .FilterAnchor {
        padding: 0 30px 0 10px !important;
    }

    .HoF-browse .productFilterList .FilterListItem {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .HoF-browse .FilterListItem a:hover * {
        text-decoration: underline;
    }

    .HoF-browse .SelectedFilter {
        margin-top: 0 !important;
    }

    .HoF-browse #amount {
        display: none;
    }

    .flanProdList .Responsive .mobAppliedFilters {
        display: none !important;
        visibility: hidden;
        letter-spacing: 0.1em;
    }

    .flanProdList .Responsive .mobAppliedFilters[data-item="apri"] {
        visibility: visible;
        padding-left: 5px;
        vertical-align: text-top;
    }

    .flanProdList .MobSortSelector .FilterListItem label span,
    .flanProdList .SelectedFilter .FilterName,
    .flanProdList .SelectableFilter .FilterName {
        letter-spacing: 0.1em;
    }

    .flanProdList .Responsive .MobSortSelector .mobAppliedFilters {
        color: #ccc;
        vertical-align: middle;
    }

    .flanProdList .SelectedFilter .FilterName {
        font-weight: var(--fw-bold);
    }

    .flanProdList .SelectedFiltersWrapper.globalSelectedFilters ul {
        border: none;
    }

    .mobileSelectedFilters .selectedFilters li.selectedFilter {
        background-color: #0f1a30 !important;
    }

    .mobileSelectedFilters .selectedFilters li.selectedFilter a.selectedFilterToggle {
        text-transform: none !important;
        color: #fff !important;
        font-weight: var(--fw-normal) !important;
    }

    .flanProdList #mobControlBar #filterByMob span,
    .flanProdList .SelectedFiltersContainer.mobileSelectedFilters ul li.selectedFilter a,
    .flanProdList .Responsive .productFilterList .FilterAnchor,
    .flanProdList .Responsive .productFilterTitle {
        letter-spacing: 0;
    }
}

@media (max-width: 767px) {
    .s-productthumbtext {
        padding: 0 5px;
    }
}

.StoreLocator h1 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
}

.Responsive .Responsiveaccountsdisplay,
.Responsive .StoreResultsMessage,
.Responsive #StoreDetailsText .Storefirstcollum > p,
.Responsive #StoreDetailsWrapper a.StoreFinderDirectionsButton.DirectionsBtn,
.StoreFinderBalloonContainer .StoreFinderBalloonLinks .DrivingDirectionsMapLink,
.StoreFinderBalloonContainer .StoreFinderBalloonLinks .drivingDirectionsBreak {
    display: none;
}

.Responsive #StoreFinderResultsListing .backButton span,
.Responsive #StoreDetailsWrapper .backButton span,
.StoreFinderResultsDetailsLinkContainer a,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton span,
.SeeOnMapContainer .SeeOnMapLink,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap {
    display: inline-block;
    width: auto;
    position: relative;
    cursor: pointer;
}

.Responsive .StoreFinderResultsLink,
.Responsive .StoreFinderResultsLinkMobile {
    font-size: 1.4em;
    text-transform: uppercase;
    color: #000;
}

.StoreFinderResultsDetailsLinkContainer {
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: none;
}

.Responsive .StoreFinderResultsDetailsLinkContainer a {
    width: auto;
    padding: 0;
}

.Responsive .SeeOnMapContainer {
    display: block;
}

.Responsive #StoreDetailsText .SeeOnMapContainer {
    padding-bottom: 20px;
    margin-bottom: 10px;
}

.SeeOnMapContainer {
    border-bottom: 1px solid #000;
}

.SeeOnMapContainer .SeeOnMapLink {
    text-transform: uppercase;
    padding-bottom: 20px;
    font-size: 12px;
}

.Responsive #StoreDetailsText .StoreFinderList,
.Responsive #StoreDetailsText .Storesecondcollum {
    line-height: 20px;
}

.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton {
    cursor: pointer;
    text-align: center;
}

#StoreFinderResultsListing,
.Responsive #StoreDetailsContainer,
.Responsive .map-wrap {
    border: none !important;
}

.Responsive #StoreDetailsText .Storefirstcollum,
.Responsive #StoreDetailsText .Storesecondcollum {
    padding: 0 5px;
    width: 100%;
}

.Responsive .Storefirstcollum {
    border: none;
    min-height: 0;
}

.Responsive #StoreDetailsText .BoldTitle {
    text-transform: uppercase;
    font-size: 12px;
}

.Responsive #StoreDetailsText .BoldTitle .glyphicon {
    display: none;
}

.Responsive #StoreDetailsWrapper .UnderlineitStorefinder {
    border-bottom: none;
}

.ShowInGoogleMaps,
.showInMapsBreak,
.Responsive #StoreDetailsWrapper a.StoreFinderDirectionsButton.ShowInMapsBtn {
    display: inline-block;
}

.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: var(--fw-bold);
}

.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton,
.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap {
    padding: 15px 40px;
}

.Responsive #StoreDetailsWrapper .backButton {
    float: left;
}

.Responsive #StoreFinderResultsMap {
    width: 100%;
}

#StoreDetailsContainer h1 {
    text-align: left;
}

#StoreDetailsContainer h1 img,
.StoreFinderBalloonAttributes {
    display: none;
}

#StoreFinderResultsAttributes ul li::before {
    content: "- ";
}

.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap {
    font-weight: var(--fw-bold);
}

.Responsive #StoreFinderResultsListing,
.Responsive #StoreDetailsContainer {
    display: flex;
    flex-direction: column;
}

.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .StoreDetailBottomActions {
    order: -1;
}

.Responsive #StoreFinderResultsListing .backButton {
    align-self: flex-start;
}

@media (min-width: 1022px) {
    #StoreFinderListWrapper #StoreFinderHeader h1,
    .StoreLocator .searchstores.storedetails h1 {
        padding: 41px 25px 20px;
    }

    .StoreLocator h1 {
        font-size: 2em;
    }

    .Responsive #StoreFinderResultsListing,
    .Responsive #StoreDetailsContainer {
        width: 50%;
        float: right;
    }

    .Responsive #StoreFinderResultsMap {
        width: 50%;
        float: left;
    }

    .Responsive #StoreDetailsWrapper .backButton {
        margin-right: 25px;
    }
}

@media (max-width: 1021px) {
    #StoreFinderListWrapper,
    #StoreDetailsWrapper {
        padding: 0;
        margin-top: 10px;
    }

    #StoreFinderListWrapper #StoreFinderResultsListing,
    .Responsive #StoreFinderResultsMap {
        padding: 10px 20px;
    }

    .Responsive #StoreFinderHeader h1,
    .Responsive #StoreFinderHeader .StoreResultsMessage,
    .Responsive #StoreFinderResultsListing {
        width: 100%;
    }

    .Responsive #StoreFinderResultsListing .backButton {
        width: 100%;
        margin: 0;
        padding: 10px 0;
    }

    .Responsive #StoreDetailsWrapper .StoreDetailBottomActions .backButton,
    .Responsive #StoreDetailsWrapper .StoreDetailBottomActions .StoreFinderDirectionsButton {
        width: 100%;
        padding: 15px;
        float: none;
    }

    .Responsive #StoreDetailsWrapper .StoreDetailBottomActions .StoreFinderDirectionsButton {
        text-align: center;
        margin-bottom: 10px;
    }

    #StoreDetailsText .ImgButWrap.button {
        display: none;
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    .Responsive #StoreDetailsContainer {
        padding: 15px;
        width: 100%;
    }

    .Responsive #StoreDetailsContainer h1 {
        text-align: left;
    }

    .Responsive .storedetails h1 {
        padding: 10px;
    }
}

@media (max-width: 767px) {
    #StoreFinderHeader,
    .Responsive #StoreFinderResultsListing,
    .Responsive #StoreDetailsContainer {
        margin: 0 0 15px;
    }

    .Responsive .StoreFinderResultsDetailsLinkContainer,
    .Responsive #StoreDetailsContainer {
        text-align: left;
    }

    .Responsive #StoreDetailsContainer h1 {
        text-align: left;
        padding: 15px 5px;
    }

    .Responsive .StoreFinderResultsDetailsLinkContainer {
        padding-top: 0;
    }

    .Responsive .StoreFinderResultsDetailsLinkContainer a {
        background-color: transparent !important;
        color: #000 !important;
    }

    .ConcessionStoresWrapper .Logos {
        justify-content: left;
    }

    .ConcessionStoresWrapper .Logos > div {
        padding: 0 10px 5px 0;
    }
}

.Profile .mainBody > .row > .col-xs-12 {
    min-height: 0;
}

.Profile .ContentPane {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
}

#accountSection #accountMenu {
    border: 1px solid #ccc;
}

#accountSection #accountMenu ul.sidebar-nav {
    display: block;
    text-align: left;
    background-color: transparent;
    padding: 0;
}

#accountSection #accountMenu ul.sidebar-nav li {
    display: block;
    text-align: left;
    font-size: 1.2em;
}

#accountSection #accountMenu ul.sidebar-nav li a {
    padding: 10px 20px;
    font-size: 1.1em !important;
}

#accountSection #accountMenu ul.sidebar-nav li.notVisible {
    display: none;
}

.cprefs .cprefsFrequency label {
    letter-spacing: 1px;
}

#accountMain {
    max-width: 1200px;
    padding: 40px 15px 0;
    margin: 0 auto;
}

#accountMain .row > div {
    padding: 8px;
    height: 220px;
}

#accountMain .row > div > div {
    padding: 25px 15px;
    text-align: center;
    height: 100%;
    border: 1px solid #ccc;
}

#accountMain h3 {
    text-transform: uppercase;
    font-size: 1.4em;
    padding-bottom: 15px;
}

#accountMain span {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    background-size: 1200px 1200px;
}

#accountMain .accBoxPersonal span {
    background-position: -140px -986px;
}

#accountMain .accBoxRecognition span {
    background-position: -137px -1062px;
}

#accountMain .accBoxMyOrders span {
    background-position: -395px -1057px;
}

#accountMain .accBoxPayment span {
    background-position: -498px -1060px;
}

#accountMain .accBoxWishList span {
    background-position: -134px -92px;
}

#accountMain .accBoxPreferences span {
    background-position: -313px -1063px;
}

#accountMain a {
    display: block;
}

#accountMain a + a {
    margin-top: 10px;
}

@media (max-width: 767px) {
    #accountMain {
        padding: 0 15px;
    }
}

.Profile.Profile-v1 .s-profile-fix fieldset {
    border: none;
}

.Profile .ProfileWrap label {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
}

.Profile .ProfileWrap .dnnFormItem {
    display: block;
    max-width: 500px;
    margin: 0 auto;
}

.dnnFormItem .SignupConfirm input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-top: 3px;
}

.Profile .ProfileWrap .dnnFormItem input,
.Profile .ProfileWrap .dnnFormItem .dnnFormInput,
.Profile .ProfileWrap .dnnFormItem textarea,
.Profile .ProfileWrap .dnnFormItem select {
    max-width: 100%;
}

.Profile .ProfileWrap .dnnFormItem input {
    padding: 15px;
}

.Profile .lastSection {
    text-align: center;
}

.Profile .lastSection .ImgButWrap {
    display: inline-block;
}

.Profile .ProfileWrap .saveBtn,
.Profile .ProfileWrap .cancel_btn {
    display: inline-block;
    width: 100%;
    text-align: center;
    float: none;
}

.Profile .ProfileWrap .saveBtn a {
    display: inline-block;
    width: auto;
}

.Profile .ProfileWrap.cprefs .saveBtn {
    position: relative;
    width: auto;
}

.Profile.WishListEvent .ContentPane {
    padding: 0;
}

#zoomMainImagePanel {
    background-color: #fff;
}

#zoomMainImagePanel p.zoom-error {
    margin-top: 315px;
}

.Profile .ProfileWrap .termsText,
.Profile .productcolour,
.Profile .productsize {
    display: block;
}

.productsize,
.productcolour {
    font-size: 12px;
}

.productcolour strong,
.productsize strong {
    text-transform: uppercase;
    font-weight: var(--fw-normal);
}

.productcolour strong span::after,
.productsize strong span::after {
    content: " -";
}

.Profile .Wishlistcontainer {
    border: none;
}

.Profile #WishListDisplay .WishListProductAddBagOrRemove .ImgButWrap a {
    color: #000;

    &:hover {
        background-color: #0f1a30;
        color: #fff !important;
    }
}

.WishListProductQuantityControls,
#BasketDiv .BagandCheckoutBtn .ImgButWrap a::before {
    display: none;
}

#BasketDiv .BagandCheckoutBtn {
    text-align: center;
}

#BasketDiv .BagandCheckoutBtn .ImgButWrap {
    float: none;
}

#addressbook-list ul li .addressbook-type {
    margin-top: 15px;
}

#addressbook-list ul li .addressbook-type span,
#addressbook-list ul li .addressbook-type a {
    padding-left: 30px;
    background: none;
    display: block;
    position: relative;
}

#addressbook-list ul li .addressbook-type span {
    text-decoration: none;
}

#addressbook-list ul li .addressbook-type span::before,
#addressbook-list ul li .addressbook-type a::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    transform: translateY(-50%);
}

#addressbook-list ul li .addressbook-type span::before {
    border: 7px solid #000;
}

#addressbook-list ul li .addressbook-type a:hover::before {
    border-color: #666;
}

#addressbook-list .addressbook-type a:hover {
    text-decoration: none;
}

.Profile .cprefsIntro {
    font-size: 1.2em;
}

@media (min-width: 1022px) {
    .Profile #WishListDisplay {
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    #divMobileView {
        display: block !important;
    }

    #accountSection {
        display: none;
    }
}

@media (max-width: 1021px) {
    .Profile .ProfileWrap .accsubHead > h2 {
        text-align: center;
        text-transform: uppercase;
    }

    .Profile.WishListEvent .ContentWrapper {
        padding: 10px 0;
    }
}

@media (max-width: 767px) {
    .productcolour,
    .productsize {
        font-size: 12px;
    }
}

.recognisedLoginForm {
    text-align: center;
    color: #66676c;
    font-size: 14px;
}

.recognisedLoginForm .paddedCol {
    padding-left: 15px;
    padding-right: 15px;
}

.recognisedLoginForm .paddedCol > div {
    margin-left: 0;
    margin-right: 0;
}

.recognisedLoginForm .formHeader {
    margin-top: 30px;
    margin-bottom: 15px;
}

.recognisedLoginForm .formSubHeader {
    margin-bottom: 15px;
}

.recognisedLoginForm .formHeader h1 {
    font-size: 34px;
    font-weight: var(--fw-bold);
    color: #1c1c1c;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 15px;
}

.recognisedLoginForm .formSubHeader h3 {
    font-size: 18px;
    font-weight: var(--fw-normal);
    color: #1c1c1c;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 7px;
}

.recognisedLoginForm .currentEmail {
    font-size: 14px;
}

.recognisedLoginForm .currentEmail > span {
    color: #1c1c1c;
    font-weight: var(--fw-normal);
}

.recognisedLoginForm .InputLabelGroup {
    text-align: left;
    min-height: 87px;
}

.recognisedLoginForm .InputLabelGroup > div input {
    font-size: 16px;
    max-width: 100%;
    font-weight: var(--fw-normal);
    color: #1c1c1c;
    height: 60px;
    padding: 5px 15px;
    border-color: #dedede;
}

.recognisedLoginForm .InputLabelGroup > div input:focus {
    border-color: #66676c;
}

.recognisedLoginForm .InputLabelGroup label {
    color: #1c1c1c;
    font-size: 12px;
    font-weight: var(--fw-bold);
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.recognisedLoginForm .ImgButWrap > a:link {
    width: 250px;
    height: 50px;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #0f1a30;
}

.recognisedLoginForm .SectionTops {
    display: none;
}

.recognisedLoginForm .alternateLoginOptions {
    width: 100%;
    left: 0;
    top: 200px;
}

.recognisedLoginForm .alternateLoginOptions hr {
    margin: 20px 0;
    border-color: #ccc;
}

.recognisedLoginForm .alternateLoginOptions .alternateLoginLinks {
    font-size: 14px;
}

.recognisedLoginForm .alternateLoginOptions .alternateLoginLinks a {
    text-transform: uppercase;
    font-weight: var(--fw-normal);
    font-size: 12px;
    letter-spacing: 1px;
}

.recognisedLoginForm .alternateLoginOptions .alternateLoginLinks a,
.recognisedLoginForm .loginContainer .ForgotPass a.ForgotPasswordLinkButton {
    color: #000;
    text-decoration: none;
    font-weight: var(--fw-normal);
}

.recognisedLoginForm .alternateLoginOptions .alternateLoginLinks a:hover,
.recognisedLoginForm .loginContainer .ForgotPass a.ForgotPasswordLinkButton:hover {
    text-decoration: underline;
}

.recognisedLoginForm .loginContainer .ForgotPass a.ForgotPasswordLinkButton {
    font-size: 14px;
}

.recognisedLoginForm .loginContainer {
    margin-bottom: 30px;
}

.recognisedLoginForm .loginContainer > div {
    width: 100%;
    display: table;
}

.recognisedLoginForm .loginContainer .NewCustWrap {
    position: static;
    display: table-footer-group;
}

.recognisedLoginForm .loginContainer .ForgotPass {
    margin-bottom: 20px;
    text-align: right;
}

@media (max-width: 767px) {
    .recognisedLoginForm .formHeader h1 {
        font-size: 24px;
    }

    .recognisedLoginForm .formSubHeader h3 {
        font-size: 16px;
    }
}

.recogniseRegisterForm {
    text-align: center;
    font-weight: var(--fw-bold);
}

.recogniseRegisterForm .form-group {
    margin-bottom: 30px;
}

.recogniseRegisterForm h1 {
    font-size: 24px;
    color: #1c1c1c;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.SortQtyName span:first-of-type,
.productFilter .productFilterList .brandSearchSort {
    text-transform: none;
    letter-spacing: 0.1em;
}

.productFilterList .SortQtyName a {
    letter-spacing: 0.1em;
}

.recogniseRegisterForm .buttonSubmit {
    width: 250px;
    height: 50px;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #0f1a30;
}

.recogniseRegisterForm .accountExists {
    font-size: 14px;
    color: #66676c;
}

.recogniseRegisterForm .accountExists a {
    color: #000;
}

.recogniseRegisterForm .accountExists a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .recogniseRegisterForm h1 {
        font-size: 18px;
    }

    .recogniseRegisterForm > .row {
        padding: 0 15px;
    }
}

.recogniseRegisterForm .dnnFormError {
    font-size: 13px;
    font-weight: var(--fw-normal);
    text-align: left;
    color: #c00;
    border-top: 2px solid #c00;
    width: 100%;
    position: relative;
    top: -1px;
    padding: 0;
}

.recogniseRegisterForm .dnnFormError::before {
    content: "";
    font-size: 27px;
    speak: none;
    vertical-align: middle;
    display: inline;
    background-size: 700px 700px;
    background-position: -77px -654px;
    padding-left: 30px;
    margin-right: 5px;
}

.loyalty h1,
.loyalty h2 {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    letter-spacing: 1px;
    color: #000;
}

.loyalty h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.loyalty h2 {
    font-size: 15px;
    margin-bottom: 10px;
}

.loyalty .loyaltyLabel,
.loyalty .lostCardDetails .cardNumberLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: var(--fw-bold);
    color: #000;
    font-size: 13px;
}

.loyalty.accountRegisterLoyalty .link.linkBack a {
    font-weight: var(--fw-bold);
    font-size: 12px;
    color: #000;
    text-transform: uppercase;
}

.loyalty.accountRegisterLoyalty .accountLoyaltyForm .dnnFormItem.lostCardDetails {
    text-align: center;
}

.loyalty .lostCardDetails .cardNumberLabel {
    margin-bottom: 10px;
}

.loyalty .lostCardDetails .cardNumber {
    margin-bottom: 5px;
    font-size: 12px;
}

.loyalty .lostCardDetails .warningText {
    text-transform: uppercase;
    margin: 25px 0;
    font-weight: var(--fw-bold);
    font-size: 13px;
}

.loyalty.loyaltyContainer.pointsAndRewards {
    max-width: 1440px;
    margin: auto;
    padding: 40px 20px 0;
    border: solid 1px #ddd;
    margin-top: 30px;
}

.loyalty.loyaltyContainer.pointsAndRewards .currentPoints .pointsVal span,
.loyalty.loyaltyContainer.pointsAndRewards .currentPoints .worthVal span {
    color: #000;
    text-transform: uppercase;
}

.loyalty.loyaltyContainer.pointsAndRewards .activitySelectBorder {
    border-width: 0 0 1px;
    border-color: #000;
    padding-bottom: 2px;
}

.loyalty.loyaltyContainer.pointsAndRewards .activitySelectBorder p {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: var(--fw-bold);
}

.loyalty.loyaltyContainer.pointsAndRewards .activitySelectBorder select {
    text-transform: uppercase;
}

.loyalty.loyaltyContainer.pointsAndRewards .topSummary {
    padding: 0 35px;
}

.loyalty.loyaltyContainer.pointsAndRewards .topSummary .nextConversion {
    display: none;
}

.loyalty.loyaltyContainer.pointsAndRewards .topSummary .activitySelect {
    width: 66.6%;
}

.loyalty.loyaltyContainer.pointsAndRewards .tableGroup {
    box-shadow: none;
    max-width: 100%;
    background-color: #fff;
}

.loyalty.loyaltyContainer.pointsAndRewards .tableGroup .tblPointsTable {
    box-shadow: none;
}

.loyalty.loyaltyContainer.pointsAndRewards .tblPointsTable .contentRow div,
.tblPointsTable .headerRow h3 {
    border-left: 0;
    border-right: 0;
    color: #000;
    width: 25% !important;
}

.loyalty.loyaltyContainer.pointsAndRewards .tblPointsTable .contentRow div span.storeIco {
    display: none;
}

.tblPointsTable .headerRow h3 {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.loyalty.loyaltyContainer.pointsAndRewards .tblPointsTable .contentRow div p {
    color: #000;
}

.loyalty.loyaltyContainer.pointsAndRewards .tblPointsTable .contentRow,
.tblPointsTable .headerRow {
    border-bottom: solid 1px #ccc;
    padding: 10px 0;
}

.loyalty.loyaltyContainer.pointsGroup .tblPointsTable .headerRow h3:nth-of-type(2),
.loyalty.loyaltyContainer.pointsGroup .tblPointsTable .contentRow div:nth-of-type(2),
.loyalty.loyaltyContainer.pointsGroup .tblPointsTable .headerRow h3:nth-of-type(3),
.loyalty.loyaltyContainer.pointsGroup .tblPointsTable .contentRow div:nth-of-type(3),
.loyalty.loyaltyContainer.rewardsGroup .tblPointsTable .headerRow h3:nth-of-type(2),
.loyalty.loyaltyContainer.rewardsGroup .tblPointsTable .contentRow div:nth-of-type(2) {
    display: none !important;
}

.loyalty.loyaltyContainer.pointsAndRewards .noTrans {
    line-height: 18px;
}

.loyalty.loyaltyContainer.pointsAndRewards .loyaltyCardTnC {
    display: none;
}

.loyalty.loyaltyContainer.pointsAndRewards .helpIco {
    display: none;
}

.accountRegisterLoyalty label,
.ModSDRegistrationC .rbList label {
    font-weight: var(--fw-normal);
}

.accountRegisterLoyalty label,
.ModSDRegistrationC .RegLabel label {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
}

.accountRegisterLoyalty .dnnFormItem input,
.ModSDRegistrationC .dnnFormItem input,
.accountRegisterLoyalty .dnnFormItem select,
.ModSDRegistrationC .dnnFormItem select {
    padding: 15px;
}

.pstrength-container {
    max-width: 382px;
}

@media (max-width: 1024px) {
    .loyalty.loyaltyContainer.pointsAndRewards {
        background: none;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .currentPoints {
        width: 100%;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .currentPoints p {
        float: none;
        text-align: center;
        width: 100%;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .currentPoints p span {
        float: none;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .activitySelect .activitySelectBorder {
        float: none;
        text-align: center;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .topSummary .activitySelect {
        width: 100%;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .topSummary {
        box-shadow: none;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .tableGroup .tblPointsTable > div.hidden-xs {
        display: block !important;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .tableGroup .tblPointsTable > div.visible-xs {
        display: none !important;
    }

    .activitySelectBorder {
        float: right;
        padding: 10px;
    }

    .activitySelect p {
        width: auto;
        text-align: left;
    }

    .activitySelect select {
        float: right;
        text-align: left;
        width: auto;
        font-weight: var(--fw-bold);
    }

    .rewardsGroup .currentPoints {
        line-height: 45px;
    }

    .currentPoints span {
        color: #000;
    }

    .currentPoints .pointsVal,
    .worthVal {
        font-weight: var(--fw-normal);
    }

    .currentPoints .pointsVal span,
    .worthVal span {
        font-weight: var(--fw-bold);
    }
}

@media (max-width: 767px) {
    .loyalty.loyaltyContainer.pointsGroup .tblPointsTable .headerRow h3:nth-of-type(6),
    .loyalty.loyaltyContainer.pointsGroup .tblPointsTable .contentRow div:nth-of-type(6),
    .loyalty.loyaltyContainer.rewardsGroup .tblPointsTable .headerRow h3:nth-of-type(5),
    .loyalty.loyaltyContainer.rewardsGroup .tblPointsTable .contentRow div:nth-of-type(5) {
        display: none !important;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .tblPointsTable .contentRow div,
    .tblPointsTable .headerRow h3 {
        width: 32% !important;
    }

    .tblPointsTable .headerRow h3 {
        display: inline-block;
        height: 50px;
    }

    .loyalty.loyaltyContainer.pointsAndRewards .activitySelectBorder {
        border-bottom: 1px solid #000;
    }

    .loyalty.loyaltyContainer.pointsAndRewards {
        padding: 40px 5px 0;
    }
}

.ReactivateRequest .noRewards {
    display: none;
}

#productDetails .rating-container {
    margin-top: 20px !important;
    min-height: 19px;
    padding: 0;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container {
    margin: 0 !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_averageRating_component_container {
    display: none !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_component_container {
    padding-top: 1px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    padding-right: 8px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 5px !important;
}

.ProdDetails div.bv_modal_component_container[data-bv-modal] {
    z-index: 6000 !important;
}

#productlistcontainer .reviews-container {
    margin-top: 10px;
    min-height: 20px;
    text-align: left;
    padding-left: 10px;
}

.reviews-container [data-bv-show="inline_rating"] > div {
    display: block !important;
}

.reviews-container [data-bv-show="reviews"],
.reviews-container [data-bv-show="questions"] {
    padding: 15px 0 !important;
}

.bv_modal_component_container .bv_histogram_row_container {
    height: auto !important;
    padding: 5px !important;
    display: block !important;
}

.bv_modal_component_container .bv_button_component_container {
    padding-top: 15px !important;
}

.bv_modal_component_container .bv_histogram_row_star {
    padding-left: 3px !important;
}

.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    width: 100% !important;
    padding: 15px !important;
    transition: background-color 0.3s ease-in;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-wide .bv-mbox {
    background-color: #f0f0f0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate
    .bv-mbox-fullprofile
    .bv-sidebar
    .bv-author-profile-userinfo
    .bv-author-userstats-list
    .bv-author-userstats-value {
    background-color: rgba(25, 25, 25, 0.1) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more {
    text-transform: uppercase !important;
    background-color: #0f1a30 !important;
    color: #fff !important;
    padding: 10px !important;
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-bold) !important;
    border: 0 !important;
    width: auto !important;
    min-width: 200px !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover {
    background-color: #777 !important;
    color: #fff !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    .bv-content-btn-pages-load-more-text {
    text-transform: uppercase !important;
    cursor: pointer !important;
    position: relative !important;
    letter-spacing: 0.1em !important;
}

.reviews-container .bv-cv2-cleanslate .bv-content-header .bv-content-title-container {
    padding-top: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-content-title {
    padding-top: 25px !important;
}

@media (max-width: 1021px) {
    .reviews-container [data-bv-show="reviews"],
    .reviews-container [data-bv-show="questions"] {
        padding: 20px 10px !important;
    }
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin: 10px 5px 10px 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-bottom: 30px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-noreviews-label,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    vertical-align: middle !important;
    line-height: 20px !important;
    margin: 10px 5px 10px 10px !important;
    display: inline-block !important;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1.2em !important;
    font-size: 1.2em !important;
    padding: 0 !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel1,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel2 {
    display: inline-block !important;
    float: none !important;
}

.reviews-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    line-height: 15px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    display: none !important;
}

.NonBuyableOverlayMessage {
    line-height: 1.35em;
}

@media (min-width: 1022px) {
    .NonBuyableOverlayMessage {
        line-height: 1.4em;
    }
}

@media (max-width: 1024px) {
    .s-productthumbbox .hotspotbuy {
        display: block !important;
    }

    .hotspotbuy .WishIcon {
        background-position: -121px -28px;
        background-size: 400px 400px;
    }
}

.flanProdList .hotspotbuy.hotspotwishlist {
    position: relative;
    right: 0;
}

.flanProdList .columns4 .hotspotbuy.hotspotwishlist {
    right: 0;
}

.flanProdList .refineByLeftHeader > .sortbyfilter {
    float: right;
    width: auto;
    line-height: 1em;
}

.flanProdList .ddlSortOptions {
    width: auto;
    border: none;
    font-size: 1em;
    padding: 30px;
    right: -30px;
    z-index: 5010;
}

.flanProdList .ddlSortOptions .ddlSortOption[data-selected="true"] span {
    font-weight: var(--fw-bold);
}

.flanProdList #divSortOptions option {
    direction: rtl;
}

.flanProdList #divSortOptions select {
    text-align-last: right;
}

.flanProdList .QuickLookIcon,
.flanProdList .sortOptionsHeader .selectedOption,
.flanProdList #showFilterButtonDiv {
    display: none;
}

.flanProdList .FiltersText::after,
.flanProdList .sortbyfilter .lblSortBy::after,
.flanProdList #CollapseDiv .productFilterTitle::after,
.flanProdList #CollapseDiv.FilterClosed .productFilterTitle::after,
.flanProdList #filterByMob .MobFiltersText::after,
.FlanProdDet #productDescReturns .collapsed .glyphicon-chevron-right::before,
.flanProdList .productFilters .productFilterTitle::after {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    background-size: 500px 500px;
    background-position: -71px -179px;
}

.flanProdList #prdlistinformation {
    margin-left: 0;
    border-right: none;
    padding-top: 11px;
}

.flanProdList .pagination .PageNumber {
    padding-top: 5px;
}

.flanProdList .productFilters .productFilterTitle::after {
    background-position: -115px -179px;
}

.flanProdList #filterByMob .MobFiltersText::after,
.flanProdList #CollapseDiv .productFilterTitle::after {
    margin-top: 2px;
}

.FlanProdDet #productDescReturns .glyphicon-chevron-right::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    background-size: 500px 500px;
    background-position: -28px -179px;
}

.flanProdList .filtersOpen .FiltersText::after,
.flanProdList .sortbyfilter .ddlSortOptionsOpen .lblSortBy::after,
.flanProdList #CollapseDiv .productFilterTitle::after {
    background-position: -26px -179px;
}

.flanProdList .DesktopHide .toggleFilterInner,
.flanProdList .toggleFilterInner {
    top: 22px;
    left: -25px;
}

.flexFilters {
    display: flex;
}

.paginationWrapper .mobSortFilter {
    position: static;
}

.mobSortFilter .mobFilterContainer,
.mobSortFilter .productFilterTitleBox {
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
    width: 100%;
}

.mobSortFilter #mobSortFilter,
.mobSortFilter .productFilterTitleBox {
    padding: 0;
}

.mobSortFilter .productFilterTitleBox {
    justify-content: flex-end;
}

.productFilterTitleBox .glyphicon,
.mobFilterAppIcon {
    height: 20px;
    top: 5px;
    right: 20px;
    width: 20px;
}

.MobSortSelector .Responsive .productFilterTitleBox,
.MobSortSelector .productFilterTitleBoxCLOSED {
    padding: 5px;
}

.productFilterTitleBox .glyphicon::before,
.productFilterTitleBox.FilterClosed .glyphicon::before,
.sdlProdList .glyphicon-ok-sign::before,
.mobFilterAppIcon::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    background-size: 1200px 1200px;
    background-position: -145px -305px;
}

.mobFilterAppIcon.glyphicon.glyphicon-ok-sign {
    display: none !important;
}

.productFilterTitleBox.FilterClosed .glyphicon::before,
.sdlProdList .glyphicon-ok-sign::before,
.mobFilterAppIcon::before {
    background-position: -41px -305px;
}

#mobControlBar {
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: 1px solid #ddd;
}

#mobSortFilter {
    padding: 0;
}

#filterByMob {
    position: relative;
    text-align: left;
    top: 0;
    transform: none;
}

#filterByMob .MobFiltersText {
    width: 100%;
    text-align: center;
}

.flanProdList .MobSortSelector .productFilterTitleBox .productFilterTitle {
    text-align: right;
    text-transform: none;
    font-weight: var(--fw-bold);
    font-family: var(--brand-font) !important;
}

.mobAppliedFilters {
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 0.1em;
}

.mobSortFilter .mobAppliedFilters {
    font-size: 1em;
    height: auto;
}

.mobSortDesc {
    width: 100%;
}

.mobSortDesc .productFilterTitle {
    letter-spacing: 0.05em;
    font-size: 1.3em;
    line-height: 1.4em;
}

.flanProdList .Responsive .mobDdClose .clsFilterIcon {
    background-size: 700px 700px;
    background-position: -274px -245px;
}

.flanProdList .refineByLeftHeader h2 .FiltersText {
    letter-spacing: 0.1em;
    font-weight: var(--fw-bold);
}

.flanProdList .sortOptionsHeader .lblSortBy {
    font-weight: var(--fw-bold);
}

@media (max-width: 1021px) {
    #filterByMob .MobFiltersText {
        text-align: left;
        color: #000;
        text-transform: none;
        font-size: 16px;
    }

    .flanProdList #mobControlBar {
        background-color: #fff;
    }
}

@media (max-width: 1021px) {
    .flanProdList .BreadcrumbGroupWrapper {
        padding: 0 15px;
    }

    .flanProdList #FilterContainer {
        top: 55px;
        max-height: calc(100% - 55px);
        box-shadow: none;
        left: 100%;
        transition: left 0.3s ease-in-out;
    }

    .flanProdList #FilterContainer.activeFilter {
        left: 0;
    }

    #innerfiltercontainer .MobSortSelector {
        display: none;
    }

    .flanProdList .brandSearchSort {
        padding: 15px 10px 5px;
    }

    .flanProdList .brandSearchSort .SortQtyName {
        display: none;
    }

    .flanProdList .Responsive .productFilterList .FilterAnchor {
        padding: 10px 0;
    }

    .flanProdList .MobSortSelector .productFilterTitleBox .productFilterTitle {
        padding: 0;
    }

    .flanProdList .productFilterTitleBox .glyphicon {
        position: absolute;
        right: 10px;
        top: 4px;
    }

    .flanProdList .productFilterTitleBox .glyphicon::before {
        background-size: 1200px 1200px;
        background-position: -145px -305px;
    }

    .flanProdList .productFilterTitleBox.FilterClosed .glyphicon::before {
        background-position: -41px -305px;
    }

    .flanProdList .mobSortSelectorWrap .productFilterTitleBox .glyphicon {
        top: 50%;
        transform: translateY(-50%);
    }

    .flanProdList .Responsive .productFilterTitleBox .productFilterTitle::before,
    .flanProdList .FilterListItem a span.FilterName::before,
    .flanProdList .MobSortSelector .FilterListItem label span::before,
    .flanProdList .MobSortSelector .FilterListItem .MobSortSelectionCheckbox::before {
        display: none;
    }

    .Responsive .FilterListItem a:hover .FilterName {
        text-decoration: none;
    }

    .Responsive .s-left-header h2 {
        text-transform: none;
    }

    .Responsive .s-left-header h2 .MobFiltersText {
        letter-spacing: 0;
    }

    .flanProdList #filterlist {
        padding-top: 50px;
        white-space: nowrap;
    }

    .flanProdList #filterlist > li {
        display: block;
        float: left;
        width: 100%;
        margin-bottom: 0;
    }

    .flanProdList #filterlist > li.filter-categories {
        padding: 0;
    }

    .flanProdList #filterlist > li.filter-categories .filter-group-title {
        padding: 10px 0;
    }

    .flanProdList #filterlist .filter-values .backTitleContainer {
        margin-bottom: 15px;
    }

    .flanProdList #filterlist .filter-values .backTitleContainer .btn-back {
        display: inline-block;
        vertical-align: middle;
        width: 35px;
        height: 40px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        background-size: 700px 700px;
        background-position: -216px -241px;
    }

    .flanProdList #filterlist .filter-values .backTitleContainer .filter-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-weight: var(--fw-bold);
    }

    .flanProdList .MobSortSelector,
    .flanProdList #mobControlBar {
        border: 0;
    }

    .mobSortFilter .MobSortSelector {
        margin: 0;
    }

    .mobSortFilter .MobSortSelector .productFilterList {
        padding: 20px;
    }

    .mobSortFilter .MobSortSelector > .productFilterList {
        border: 1px solid #ddd;
        position: absolute;
        width: calc(50% - 5px);
        z-index: 960;
        background-color: #fff;
        left: auto;
        right: 0;
    }

    .mobSortFilter .MobSortSelector > .productFilterList .flanProdList .MobSortSelector input[type="radio"],
    .mobSortFilter .MobSortSelector .productFilterList .FilterListItem .FilterAnchor span::after {
        display: none;
    }

    .flanProdList .FilterAnchor .MobSortSelectionCheckbox {
        width: 100%;
    }

    .flanProdList .FilterAnchor .MobSortSelectionCheckbox.SelectedFilter span {
        font-weight: var(--fw-bold);
    }

    .flanProdList .Responsive #FilterContainer #innerfiltercontainer {
        padding: 20px 26px 80px;
    }

    .flanProdList #mobFilterControls {
        height: auto;
        background: #0f1a30;
    }

    .flanProdList #mobFilterControls li {
        height: auto;
    }

    .flanProdList #mobFilterControls li.mobApplyFilter.clearFiltersOpen {
        width: calc(58% - 5px);
    }

    .flanProdList #mobFilterControls li#mobclrfltrs {
        background-color: #888 !important;
        width: calc(42% - 5px);
    }

    .flanProdList #mobFilterControls li#mobclrfltrs:hover {
        background-color: #666 !important;
    }

    .flanProdList #mobFilterControls li .textIconWrap {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        padding: 15px;
        background-color: #0f1a30;
    }

    .flanProdList #mobFilterControls li.mobClearFilters .textIconWrap {
        background-color: #777;
    }

    .flanProdList #FiltersHeader {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 20px;
        margin-bottom: 20px;
    }

    .flanProdList .Responsive .mobDdClose {
        right: 0;
        top: auto;
    }

    .flanProdList .Responsive .productFilterTitle {
        font-size: 16px;
        padding: 0;
        width: 100%;
        margin: 0;
    }

    .flanProdList .MobSortSelector .mobFiltInnerWrap .productFilterTitle::before {
        display: none;
    }

    .flanProdList .flexFilters .productFilterTitleBox .glyphicon::before {
        background-position: -281px -323px;
    }

    .flanProdList .flexFilters .productFilterTitleBox.FilterClosed .glyphicon::before {
        background-position: -216px -323px;
    }

    #TopPaginationWrapper .paginationWrapper {
        transition: top 0.25s linear 0s;
        top: -66px;
        padding: 10px 15px 20px;
    }

    #TopPaginationWrapper .paginationWrapper .visible-xs {
        border-bottom: 1px solid #ccc;
    }

    .headerFix #ProductContainer #TopPaginationWrapper.activeFilterBar .paginationWrapper {
        position: fixed;
        z-index: 960;
        background-color: #fff;
        width: 100% !important;
        left: 0;
        top: 55px !important;
        opacity: 1;
        padding: 0 15px 0;
    }

    .FlanProdDet .helpContainer .pd-accordion .acc-content ul li a {
        padding: 10px 0;
    }

    .FlanProdDet .pd-accordion .acc-content,
    .FlanProdDet .pd-accordion .acc-content p,
    .FlanProdDet .pd-accordion .acc-content p a,
    .FlanProdDet .pd-accordion .acc-content ul li {
        letter-spacing: normal;
    }
}

@media (max-width: 767px) {
    .mobSortFilter .MobSortSelector > .productFilterList {
        border: 1px solid #ddd;
        position: absolute;
        left: 0;
        width: 100%;
    }

    #TopPaginationWrapper .paginationWrapper {
        padding: 10px 15px;
    }

    .headerFix #ProductContainer #TopPaginationWrapper.activeFilterBar .paginationWrapper {
        top: 121px !important;
    }
}

#hotspotModal .modal-body .PinWrapText {
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: right;
}

#hotspotModal .modal-body .PinImage,
#hotspotModal .modal-body {
    padding: 0;
}

#hotspotModal #addHotspotToBag,
#hsAddToWishListContainer a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0f1a30;
    text-transform: uppercase;
}

.PinWrapText .hsColourDesc {
    padding-top: 15px;
    padding-bottom: 5px;
}

#hotspotModal .PinWrapText .hsSizeSelection {
    padding-left: 0;
    padding-right: 0;
    order: 3;
}

#hotspotModal .PinWrapText .sizeGuideLink {
    order: 2;
    transform: translateY(-20px);
}

#hotspotModal .PinWrapText .hsbottom {
    order: 4;
}

#hotspotModal .PinWrapText .BuySizeText {
    border-bottom: 1px solid #d3d3d3;
    order: 1;
}

#hotspotModal .PinWrapText .sizeGuide-text {
    font-weight: var(--fw-bold);
}

#hotspotModal .modal-header {
    position: absolute;
}

#hotspotModal .PinWrapText h2 {
    padding-right: 30px;
}

#hotspotModal #hsViewProduct {
    margin-top: 20px;
}

#hotspotModal .swiper-container > .swiper-pagination-bullets {
    bottom: auto;
    top: 5px;
    text-align: left;
    padding-left: 5px;
}

#hotspotModal .swiper-button-next,
#hotspotModal .swiper-button-prev {
    background-size: 800px 800px;
}

#hotspotModal .swiper-button-prev {
    background-position: -240px -410px;
}

#hotspotModal .swiper-button-next {
    background-position: -185px -410px;
}

@media (max-width: 1021px) {
    .filter-values {
        transform: translateX(200%);
        transition: 0.5s ease-in-out;
    }

    .filter-categories,
    .SelectedFiltersWrapper,
    .child-open .filter-values {
        transform: translateX(0);
        transition: 0.5s ease-in-out;
    }

    .child-open .filter-categories,
    .child-open .SelectedFiltersWrapper {
        transform: translateX(-200%);
        transition: 0.5s ease-in-out;
    }

    .lstfilter {
        display: block;
    }

    .child-open .lstfilter,
    .child-open .SelectedFiltersContainer,
    .child-open .filter-values .productFilterList {
        display: none !important;
    }

    .child-open .filter-values .productFilterList.open {
        display: block !important;
    }

    #hotspotModal .modal-header .close {
        width: 30px;
        height: 30px;
        top: 8px;
        right: 8px;
        border: none;
        box-shadow: none;
        background-color: transparent !important;
        background-position: -158px -203px !important;
        background-size: 400px 400px !important;
    }
}

@media (max-width: 767px) {
    #hotspotModal .PinWrapImage {
        max-width: 100% !important;
    }

    .flanProdList .modal-body .PinWrapText {
        padding: 15px;
    }

    #hotspotModal .PinWrapText h2 {
        padding-right: 30px;
    }

    .FlanProdDet .ColnSize {
        padding-top: 20px;
    }

    .FlanProdDet #divColour #BuyColourText,
    .FlanProdDet .swapSize .s-productextras-column-2 ul li a {
        font-size: 1.3em;
    }
}

.flanProdList .hsVariantButtons li.hsVariantHighlight::before {
    display: none;
}

.flanProdList .hsSizeSelection .hsVariantButtons li.hsVariantHighlight a {
    color: #fff;
    background: #0f1a30;
}

.flanProdList .hsSizeSelection .hsVariantButtons li a {
    color: #0f1a30;
}

.flanProdList .hsSizeSelection .hsVariantButtons li,
.flanProdList .hsColourSelection .hsVariantButtons li.hsVariantHighlight,
.flanProdList .hsSizeSelection .hsVariantButtons li.hsVariantHighlight a {
    border: 1px solid #0f1a30 !important;
}

.flanProdList .paginationWrapper .PrevNextButtons.PageNumber a::before {
    display: none;
}

.FlanProdDet .colourImages li.greyOut span {
    background-image: none;
    background-color: rgba(235, 235, 235, 0.4);
    border: 1px solid #ccc;
}

.FlanProdDet .colourImages li.greyOut span::after {
    content: "";
    position: absolute;
    display: block;
    background: linear-gradient(45deg, transparent 0, transparent 49%, #ccc 50%, transparent 52%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a:hover {
    font-weight: var(--fw-bold) !important;
    text-decoration: none;
}

nav#topMenu li.root .SubMenuWrapper > ul li.sdmColHeader a {
    letter-spacing: 0;
}

.newBasketSummary .newBasketPromoCode #divVoucherError {
    overflow-wrap: break-word;
}

#StoreFinderListWrapper {
    margin-bottom: 25px;
}

.gwpPromotionContainer {
    justify-content: center;
}

.gwpPromotionContainer .gwpPromotion {
    max-width: 400px;
    background-color: transparent;
    border: 0;
}

.gwpPromotionContainer .gwpPromotion div.promotionInfo .promotionInfoTitle {
    font-size: 1.9em;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
}

.gwpPromotionContainer .gwpPromotion .promotionInfoProduct {
    font-size: 1.4em;
}

.gwpPromotionContainer .gwpPromotion .promotionInfoMore,
.gwpPromotion div.promotionInfo a {
    display: none;
}

.gwpPromotionContainer .gwpPromotion div.promotionInfo {
    width: calc(100% - 121px);
    padding: 0 20px;
    text-align: center;
}

.gwpPromotionContainer .gwpPromotion img.promotionInfo {
    width: 121px;
    height: 121px;
    line-height: 121px;
    margin: 0;
}

.FlanProdDet .infoaccordion p#lblProductCode {
    font-size: 15px;
    display: block;
    font-weight: var(--fw-normal);
}

.edits-megadrop {
    width: 50% !important;
}

.Edits .colunmGroup ul {
    width: 100%;
}

.Edits .colunmGroup .edits-nav-title {
    font-size: 20px;
}

.Edits .colunmGroup .edits-sub-title {
    font-size: 18px;
}

.Edits .colunmGroup .edits-nav-title a {
    display: flex;
    justify-content: center;
}

.stockLevelWrapper {
    float: left;
    width: 100%;
}

.stock-level-container {
    margin-top: 20px;
    padding: 10px;
    text-align: center;
}

.stock-level-container h4,
.stock-level-container p {
    display: inline;
    font-size: 13px;
}

.stock-level-container h4 {
    letter-spacing: 0.05em;
}

@media (max-width: 1021px) {
    .stock-level-container {
        margin-top: 10px;
    }
}

.Addresscontainer .accsubHead h1,
.OrderHistoryContainer .accsubHead h1,
.cprefs .accsubHead h1 {
    padding: 20px 0;
}

@media (min-width: 768px) {
    .OrderHistoryContainer .accsubHead h1,
    .cprefs .accsubHead h1 {
        padding-left: 20px;
    }
}

@media (min-width: 1022px) {
    .flanProdList #prdlistinformation {
        padding-top: 2px;
    }
}

.sticky-atb .originalTicket {
    font-size: 14px;
    line-height: 20px;
}

.FlanProdDet .addToBasketContainer .ImgButWrap a:hover,
.ImgButWrap a:hover {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #0f1a30;
}

@media (min-width: 1022px) {
    .FlanProdDet .addToBasketContainer {
        width: 100%;
        min-height: unset;
    }
}

.sticky-atb .sticky-atb--buysection .addToBag span {
    font-size: 16px;
}

#sticky-atb-wrapper.stickyCTA-active {
    transition: top 0.3s ease-in-out;
    top: 0;
    z-index: 5909;
    .menu-search-shown & {
        top: 81px;
        @media (min-width: 1022px) {
            top: 129px;
        }
    }
    @media (min-width: 1022px) {
        top: 129px;
    }
}

.sticky-atb .sticky-atb--buysection .sizeSelected a.addToBag:hover {
    background-color: #fff !important;
}
