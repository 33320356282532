.FlanProdDet .AltProdDet .WishListContain {
    width: 30px;

    .wishListSVG {
        line-height: 1;

        svg path {
            fill: var(--site-black);

            &.inner {
                fill: var(--site-white);
            }
        }

        &:hover {
            svg path {
                fill: #bbb;
                stroke: none;

                &.inner {
                    fill: var(--site-white);
                }
            }
        }

        &.addedWishList {
            svg path {
                fill: var(--site-black);
            }

            &:hover {
                svg path {
                    fill: #bbb;
                }
            }
        }
    }
}

.ProdDetails .member-price {
    justify-content: flex-start;
    text-align: left;
    margin: 0;
}

.reviews-container .bv-cv2-cleanslate .bv-shared .bv-action-bar button,
.reviews-container .bv-cv2-cleanslate .bv-shared .bv-action-bar button:hover {
    letter-spacing: 0.1em !important;
    font-size: 14px !important;
}

@media (max-width: 767px) {
    .frasers-plus-breakdown {
        padding: 0 15px 28px 0;
    }
}
