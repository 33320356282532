.FooterWrap .maxFooter {
    margin: 0 auto;
    max-width: var(--container-max-width);
    padding: 0 5%;

    @media (max-width: 1021px) {
        padding: 0 25px;
    }

    @media (max-width: 767px) {
        padding: 0 15px;
    }
}

.footerContentContainer {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: auto 111px;
    grid-template-areas:
        "footerLeft footerRight"
        "footerBottom footerBottom";
    margin: 30px 0 0;
    gap: 25px 0;

    @media (max-width: 1021px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            "footerLeft"
            "footerRight"
            "footerBottom";
        gap: 15px;
    }

    .footerLeft {
        grid-area: footerLeft;

        @media (min-width: 1022px) {
            padding-right: 100px;
        }
    }

    .footerRight {
        grid-area: footerRight;

        .footerMenu {
            @media (max-width: 1021px) {
                > div {
                    width: 100%;
                }
            }
        }
    }

    .footerBottom {
        grid-area: footerBottom;
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 50px;
            grid-template-areas:
                "footerLogo social"
                "copyright copyright";
        }

        @media (min-width: 1022px) {
            grid-template-columns: 1fr 340px 1fr;
            grid-template-rows: auto;
            grid-template-areas: "footerLogo copyright social";
            border-top: 1px solid var(--site-white);
        }

        @media (max-width: 767px) {
            gap: 15px;
            margin: 10px 0;

            > div,
            .socialSitesWrapper {
                justify-content: center;
            }

            .footerLogo {
                order: 1;
            }

            .Copyright {
                order: 3;
            }

            .socialSitesWrapper {
                order: 2;
            }
        }

        .footerLogo {
            display: flex;
            align-items: center;
            grid-area: footerLogo;

            a {
                display: flex;
            }

            svg {
                height: 20px;

                * {
                    fill: #fff;
                }
            }

            #divCurrencyLanguageSelector {
                margin-left: 50px;

                .currencyLanguageSelector {
                    display: flex;
                    align-items: center;
                    background: none;
                    border: none;
                }

                .spanCurrencyLanguageSelector {
                    padding: 0;
                    height: auto;

                    p {
                        color: #fff;
                    }
                }
            }
        }

        .Copyright {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-area: copyright;
            gap: 30px;
            max-width: 100%;
            margin: 0;
            padding: 0;

            span {
                margin: 0;
            }

            a {
                color: var(--site-white);

                @media (max-width: 767px) {
                    display: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .socialSitesWrapper {
            display: flex;
            grid-area: social;
            align-items: center;
            justify-content: flex-end;
            overflow: hidden;
            padding: 0;

            li {
                display: inline-block;
                height: 50px;
                width: 50px;
                position: relative;

                a {
                    text-indent: -9999px;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    max-width: 50px;
                    background-size: 700px 700px;
                }

                &.socFacebook a {
                    background-position: -487px -359px;
                }

                &.socFacebook a:hover {
                    background-position: -487px -303px;
                }

                &.socPinterest a {
                    background-position: -217px -619px;
                }

                &.socSnapChat a {
                    background-position: -201px -639px;
                }

                &.socTwitter a {
                    background-position: -332px -163px;
                }

                &.socTikTok a {
                    background-position: -561px -359px;
                }

                &.socTikTok a:hover {
                    background-position: -561px -303px;
                }

                &.socInstagram a {
                    background-position: -411px -359px;
                }

                &.socInstagram a:hover {
                    background-position: -411px -303px;
                }

                &.socYoutube a {
                    background-position: -640px -362px;
                }

                &.socYoutube a:hover {
                    background-position: -640px -306px;
                }
            }
        }
    }
}

.currencyLanguageSlider {
    top: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
}
