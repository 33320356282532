#divBag a#aBagLink {
    .global-icon-bag {
        display: flex;

        svg path {
            fill: var(--site-black);
        }
    }

    .bagIcon {
        display: none;
    }
}

@media (min-width: 1022px) {
    #divBag a#aBagLink:hover .global-icon-bag svg path {
        fill: #bbb;
    }
}

.lillBasket .innerdivBagItems {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        max-height: calc(95vh - 85px) !important;
        overflow: hidden;
    }

    #divulBagParent {
        flex: 1 1 auto;
        scrollbar-width: thin;
        scrollbar-color: var(--site-primary) var(--site-light-grey);
        max-height: 50vh;
        overflow: auto;
    }

    #divBagItemsChild {
        padding: 0;
        max-height: calc(100vh - 117px) !important;
        overflow-y: auto;
        flex-direction: column;

        @media (min-width: 768px) {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        .bagHeader p {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            text-transform: uppercase;
            font-size: 1rem;
            border-bottom: 1px solid #ddd;
            text-align: left;
            padding: 30px 24px;
            margin: 0;

            .global-icon-tick {
                margin: 0 10px;
            }
        }

        .bagHeader #clsBasketMob {
            font-size: 1em;
            width: 42px;
            right: 10px;
            font-family: initial;
            top: 18px;
            height: 42px;
            line-height: 30px;

            .global-icon-cross {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        #divButtons {
            padding: 10px 25px 25px;
            background-color: var(--site-light-grey);

            .SubBask {
                display: flex;

                & > div {
                    width: 50%;
                    flex: 1 1 auto;
                }
            }

            .summaryWrapCTA {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;

                #aViewBag,
                #aCheckout {
                    font-size: 15px;
                    font-family: var(--brand-font);
                    font-weight: var(--fw-bold);
                    text-transform: uppercase;
                    padding: 16px 5px;
                    letter-spacing: 0.1em;
                    border: solid 1px var(--site-black);
                }

                #aViewBag {
                    width: 100%;
                    background-color: var(--site-secondary) !important;
                    margin-bottom: 0;

                    &:hover {
                        background-color: var(--site-primary) !important;
                        color: var(--site-secondary) !important;
                    }
                }

                #aViewBag span {
                    display: block;
                }

                #spanCheckout {
                    width: 100%;
                }

                #aCheckout {
                    background-color: var(--site-primary);
                    margin-top: 15px;

                    &:hover {
                        background-color: var(--site-secondary);
                        color: var(--site-primary);
                    }
                }
            }
        }
    }
}

.elevated-cart {
    &-is-empty {
        .cart-page__primary-button {
            transition: all 0.25s linear 0s;
            border: solid 1px var(--site-primary);

            &:hover span {
                color: var(--site-primary);
            }
        }
    }

    .PromoCodeBut {
        letter-spacing: 0.1em;
    }

    .basket-summary-continue-button-container .ContinueOn {
        border: 1px solid var(--site-primary);
        letter-spacing: 0.1em;

        &:hover {
            background-color: var(--site-secondary) !important;

            span {
                color: var(--site-primary);
            }
        }
    }
}
