.flanProdList .hotspotbuy.hotspotwishlist {
    width: 23px;
    height: 21px;

    .wishListSVG {
        padding: 0;
        width: 23px;
        height: 21px;

        svg {
            width: 100%;
            position: static;
            transform: none;
        }
    }

    &.addedWishList {
        .wishListSVG {
            svg path.inner {
                fill: var(--site-black);
            }
        }
    }
}

#hotspotModal .swiper-button-prev,
#hotspotModal .swiper-button-next {
    background-image: none;
}

.flanProdList .QuickLookText {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
