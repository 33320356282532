#topMenuWrapper {
    height: 44px;
    background-color: var(--site-white);
    border-top: 1px solid var(--site-mid-grey);
    border-bottom: 1px solid var(--site-mid-grey);
    top: 71px;
    z-index: 1 !important;

    nav#topMenu {
        max-width: 800px;
    }

    nav#topMenu ul.RootGroup {
        height: 100%;
        text-align: center;
        display: flex;

        li.root {
            flex: 1 1 auto;
        }
    }

    nav#topMenu ul li.root {
        float: none;
        height: 100%;
        text-align: center;
        display: inline-block;
        position: static;

        & > a {
            font-size: 15px;
            letter-spacing: 1px;
            line-height: 42px;
            height: 100%;
            padding: 0 5px;
            position: relative;
        }

        &:hover {
            transition: none;

            & > a {
                font-weight: bold;
                color: var(--site-primary-light);
            }
        }

        .SubMenuWrapper {
            width: 100%;
            position: fixed;
            top: 115px;
            max-height: calc(100vh - 150px);
            overflow-y: scroll !important;
            left: 0 !important;
            padding: 15px 10%;
            border: none;
            height: auto;

            & > ul > li.Center {
                width: 60%;
                float: left;
                height: 100%;
            }

            & > ul > li.Right {
                width: 40%;
                float: right;
                height: 100%;
            }

            & > ul > li.Right .newImages {
                width: 100%;
            }

            & > ul > li.Right .newImages .postTitle,
            & > ul > li.Center .newImages .postTitle {
                font-size: 1.25em;
                padding: 15px 0 0;
            }

            & > ul > li.Right .newImages .postLink,
            & > ul > li.Center .newImages .postLink {
                display: inline-block;
                width: auto;
            }
        }
    }
}
