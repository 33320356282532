:root {
    --brand-font: DMSans, Arial, Helvetica, sans-serif;
    --brand-font-bold: DMSans, Arial, Helvetica, sans-serif;
    --fw-normal: 400;
    --fw-bold: 700;
}

@font-face {
    font-family: DMSans;
    src: url("/Fonts/DMSans-Regular.woff2") format("woff2");
    font-weight: var(--fw-normal);
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: DMSans;
    src: url("/Fonts/DMSans-Bold.woff2") format("woff2");
    font-weight: var(--fw-bold);
    font-style: normal;
    font-display: swap;
}

#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
td,
tr,
legend,
select,
button,
input[type="submit"],
label,
.prc_comment_postdatetime,
#StoreFinderResultsAttributes ul li,
nav#topMenu li.root > ul li a,
nav#topMenu > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul li a,
.PSSellPrice,
.s-productthumbtext .productdescriptionname,
.am-menu .shop ul li.level2 > a,
.FlanProdDet .pd-accordion .acc-content,
.FlanProdDet .pd-accordion .acc-content p,
.FlanProdDet .pd-accordion .acc-content p a,
.FlanProdDet .pd-accordion .acc-content ul,
.FlanProdDet .pd-accordion .acc-content ul li,
.newsletterPrivacy p,
.newsletterPrivacy a,
nav#topMenu ul li.root .SubMenuWrapper .newImages .postLink {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-normal);
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
.flanProdList .QuickLookText,
.flanProdList .refineByLeftHeader h2 .FiltersText,
.FlanProdDet .title h1,
.FlanProdDet .addToBasketContainer .ImgButWrap a span,
.formCompleteCTA button {
    font-family: var(--brand-font) !important;
    font-weight: var(--fw-bold);
}

.MenuGroupA > ul,
.MenuGroupB > ul {
    max-width: 1190px;
    width: 100%;
}

.shop > ul > li#liAllDepts a {
    background-color: transparent !important;
}

a,
a:active,
a:link,
a:visited,
.RightBox p,
.RightBox h3,
.RightBox,
.TopHeader p,
.infoTabPage p,
.infoTabPage ul,
#topLinkMenu ul li a:hover {
    color: #000;
}

a.plpactive {
    color: #b90000;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
p,
.Basket h1,
.weWrap,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.sizeText,
#productImages .imageControls .zoomControl:hover,
ins,
mark,
.Profile .field label,
.pagination .sortbyfilter label,
.Responsive .StoreFinderResultsDetailsLinkContainer a,
h6,
a:hover,
a p:hover,
.outofstock,
.csHomeTable a,
.csHomeTable a:hover,
.saleprice,
.PSSellPrice,
.SubBask .PriceTotalBask,
.discountText,
.SubTotal,
.s-largered,
.csHomeTable li.header a,
.csHomeTable li.header a:hover,
nav#topMenu > ul li.FlanHome a,
.BaskName:hover,
.AltStratSellPrice,
.originalprice,
.BaskPrice,
#FootcmdSearch,
#infoaccordion .FooterHeader span,
.s-productthumbtext a,
.Help_Title,
.s-smalltext,
.AltStratRefPrice,
.Responsive .StoreFinderResultsDetailsLinkContainer a:hover,
nav#topMenu ul li.root .SubMenuWrapper ul.New li.NewCenter .level1 a,
.OrderCompleteRegistration .SignUpLabel .NewsletterFree,
.styleNews h3,
nav#topMenu li.root .SubMenuWrapper .styleNews .blogMainHeader,
.styleNews a:hover,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postTitle,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSection1::before,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSnippet::after,
#accountMenu .sidebar-nav li.active a,
.am-menu ul li > a,
.accordionMenuContentWrap .menuitemtext,
.accordionMenuContentWrap .mob-login,
.accordionMenuContentWrap .glyphicon-chevron-right,
#topLinkMenu ul li.TopLinkDrop ul li a,
.ui-autocomplete li #ui-active-menuitem,
.s-productthumbtext .productdescriptionbrand,
nav#topMenu li.columnGroup a,
.styleNews a,
nav#topMenu ul li.root .SubMenuWrapper ul li.KidsRight.Right .kidsFeatured .postSnippet,
nav#topMenu li.columnGroup a:hover,
.accordionMenuContentWrap .shop .glyphicon-chevron-right,
.dropdown-menu li a,
nav#topMenu li.root .SubMenuWrapper .styleNews .styleNewsLink,
.styleNews .postLink,
#divBagTotalLink a#aBagLink #bagTotal,
nav#topMenu > ul li .singlecolumnadvert .view-button,
.flanProdList .Responsive .FilterClosed .productFilterTitle,
.BaskColr,
.BaskSize,
.lillBasket .BaskQuant,
#WishListDisplay .productcolour,
#WishListDisplay .productsize,
#catCopyMobileReadMoreLess,
#FiltersAndProductsWrapper a:hover .productdescriptionname,
.mp-container .mp-menu .mobMenuAccount .mp-level ul li > a,
.CareersBrand td.vacancy-more-info-btn a {
    color: #000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap a:hover,
.ImgButWrap input,
button,
.Basket #BasketDiv .UpdateQuant,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
#aPayNow,
#aViewBag,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
a.prc_comment_postcomment,
.Wishlistcontainer #ImgButWrapResponsive a,
.sg_colHead,
.Brands .allLettersBrand a,
.Brands .letTilte a,
.Brands .letTilte a:hover,
.Brands .letItems a:hover,
.Brands .whiteBoxBrand .greyBgBrand.boxContentBrand,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.bsheaderIcons #topLinkMenu ul li.SignOut a,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton,
.Responsive #StoreDetailsWrapper .backButton,
.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap,
.Profile .UpdateandAddMoresec .ImgButWrap a,
.Profile #btnSaveChanges,
.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll a,
.OrderComplete h1,
#promotion-ui .promotionPopDetails .promoOfferCTA,
.CareersBrand td.vacancy-more-info-btn a:hover,
.SocialWrapperHeader,
.FooterSubGroup .FooterHeader,
.FooterGroupLink li a,
.Copyright span,
.OrderCompleteNumber,
.accordionMenuContentWrap #spanCurrencyMenuItem,
.accordionMenuContentWrap #spanSelectedCurrency,
.showFilterButtonWrapper .showFilterButton {
    color: #fff;
}

.am-menu .accordionMenuContentWrap > ul.mobMenuGroup li,
.accordionMenuContentWrap #liMobileCurrencySelector li {
    border-bottom: 1px solid #d0d0d0;
}

.am-menu .accordionMenuContentWrap > ul.mobMenuGroup li a,
.accordionMenuContentWrap .mob-login {
    color: #fff;
}

.am-menu .accordionMenuContentWrap ul.mobMenuGroup li.has-dropdown ul li a,
.pppt {
    color: #000;
}

.search input[type="text"] {
    color: #494646;
}

.productcolour,
.productsize,
.categorycopyd4 {
    color: #555;
}

.Basket #BasketDiv table th,
.s-smalltext,
.sizeDetail,
#productImages .imageControls .zoomControl {
    color: #666;
}

.AltFooterGroups li {
    color: #747474;
}

input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled {
    color: #888;
}

#searchSuggestionRenderOverride li a:hover {
    color: #999;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader.saleColour > a,
.lineProblems .outofstock {
    color: #f00;
}

.progressBarItem,
.currencyLanguageSelector:hover .spanCurrencyLanguageSelector p {
    color: #bbb;
}

.progressBarActive,
#divBagTotalLink span,
#findAstore a,
.langlogin a.login,
.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
.Basket #BasketDiv .UpdateQuant,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
.basketLink .langlogin a.login,
#aViewBag,
.addToBasketContainer .ImgButWrap,
.pagination span.PageSelector,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .navbar-nav > li > a,
.footerDesktop h3,
.shop > ul > li > a,
.flanProdList #mobFilterControls li.mobClearFilters span,
#address-sec .addressbook-options-buttons a.dnnPrimaryAction,
.signup-wrapper input[type="button"],
.signup-wrapper input[type="button"]:hover,
.newsletterPrivacy p,
.newsletterPrivacy p a,
.signup-wrapper input#signupEmail::placeholder {
    color: #fff;
}

.Basket #BasketDiv .productcode span,
.FlanProdDet .originalprice {
    color: #ccc;
}

.RefandPrice span,
.originalprice .originalTicket {
    color: #757575;
}

#lblSellingPrice.productHasRef,
.s-producttext-withticket .CurrencySizeLarge.curprice {
    color: #bf0038;
}

.maxText {
    color: #e81515;
}

.SubMenuGroup li:last-child a {
    color: #e90808;
}

.Basket #BasketDiv table .productTitle,
#BasketDiv .productcolour,
#BasketDiv .productsize,
.spanCurrencyLanguageSelector p {
    color: #000;
}

.Basket #BasketDiv table .prodelete {
    color: #b2b2b2;
}

.Basket #BasketDiv table .prodelete:hover,
.signup-wrapper input[type="button"]:hover {
    color: #000;
}

.contentwrap,
.header,
nav#topMenu li.root > a:hover,
nav#topMenu ul li.root:hover,
#topLinkMenu ul li a:hover,
.ui-autocomplete li #ui-active-menuitem,
#divBagTotalLink a#aBagLink:hover,
.FooterSubGroup .FooterHeader,
.FooterGroup1,
.FooterGroup2,
.FooterGroup3,
.search input[type="text"],
.searchstores .store-fields-main,
#productDetails .ColnSize select:disabled,
.letTilte a,
.zoomText,
.Help_Title,
.csHomeTable li.header,
nav#topMenu > ul li,
.menu-trigger,
.Profile #WishListDisplay .WishListProductAddBagOrRemove .ImgButWrap a,
.signup-wrapper input#signupEmail,
.signup-wrapper input[type="button"] {
    background-color: transparent;
}

div.NewMenuWrap,
.mp-level,
.mp-menu ul li > a,
.ImgButWrap input,
.Basket #BasketDiv .UpdateQuant,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.pagination span.PageSelector,
.CareersBrand .HeaderWrap,
.CareersBrand .vacancy-search-submit a,
.CareersBrand td.vacancy-more-info-btn > a:hover,
.mobile-menu-container .navbar-header .navbar-toggle .icon-bar,
.OrderColC .TrackOrder a,
.DeliveryOptions .SelectButton,
.footerWrap input[type="button"],
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
#promotion-ui .promotionPopDetails .promoOfferCTA,
#topMenuWrapper2 .UserLogin a,
.HeaderLine,
.showFilterButtonWrapper .showFilterButton {
    background-color: #0f1a30;
}

body,
.cservices #profileList,
.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripTop_Vista .rtsLevel .rtsOut,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
#divBagItems,
#divEmptyErrorMessage,
#divAjaxLoaderImage,
#ulBag li,
.BodyWrap,
#BodyWrap,
.CareersBrand .vacancy-more-info-btn a,
nav#topMenu ul li.root .SubMenuWrapper,
.accordionMenuContentWrap .shop > ul > li.root.open > a,
.accordionMenuContentWrap .has-dropdown li.level1.open > a,
.am-level .shop .has-dropdown .am-level,
#topLinkMenu ul.TopSubLinkMenu > li,
.lillBasket #divButtons,
.Profile #btnSaveChanges,
.StoreFinderResultsDetailsLinkContainer a::before,
.SeeOnMapContainer .SeeOnMapLink::before,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton span::before,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap::before,
.Responsive #StoreFinderResultsListing .backButton span::before,
.Responsive #StoreDetailsWrapper .backButton span::before,
.OrderhistorySummary .OrderGroup .OrderColC .ButtonOrder a::before,
.Responsive .OrderDetailLine .col-last .orderdtls a::before,
.Login .ImgButWrap a span::before,
.lowerPass .ImgButWrap a span::before,
.BasketNew .primary2.ImgButWrap a span::before,
.mp-container .mp-menu .mobMenuAccount .mp-level ul li > a,
.CheckWrap .SignupConfirm,
.signup-wrapper input[type="button"]:hover {
    background-color: #fff;
}

.flanProdList #mobFilterControls li.mobClearFilters,
.AddToBagBar {
    background-color: #ccc;
}

.byPostGroup {
    background-color: #f7f7f7;
}

ins {
    background-color: #ff9;
}

mark {
    background-color: #ff0;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.bsheaderIcons #topLinkMenu ul li.SignOut a,
.lillBasket #aCheckout,
.Browse .swiper-pagination-bullet-active,
.mobMenuNewsletter .newsletterButtons input,
.Profile #WishListDisplay .WishListProductAddBagOrRemove .ImgButWrap a::after,
.cprefs .yesnoSelect label.selected,
.cprefs .cprefsFrequency label.selected,
#accountMenu .sidebar-nav li.active a::before,
.Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton,
.Responsive #StoreFinderResultsListing .backButton,
.Responsive #StoreDetailsWrapper .backButton,
.Responsive #StoreDetailsWrapper .SeeOnMapContainer .btnSeeOnMap,
#address-sec .addressbook-options-buttons a.dnnPrimaryAction,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderPaging input[type="submit"],
.CheckWrap input.ContinueOn,
.Responsive .storefinderSearchButtons input,
.Profile #btnSaveChanges,
.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll a,
.FooterWrap,
.PinWrapText .addToBasketContainer .ImgButWrap span a {
    background-color: #0f1a30;
    border: solid 1px #0f1a30;
}

.back-to-top {
    background-color: #253044;
}

#CheckoutSpaRoot .activeSection .sectionGroup h1::before {
    background-color: #20419a;
}

#CheckoutSpaRoot .formCompleteCTA {
    button {
        background-color: var(--site-primary);
        border: 1px solid var(--site-primary);
        letter-spacing: 0.1em;

        &:hover {
            background-color: var(--site-secondary);
            color: var(--site-primary);
            border: 1px solid var(--site-primary);
        }
    }

    &.inactiveSubmit button {
        &:hover {
            background-color: var(--site-secondary);
            color: var(--site-primary);
            border: 1px solid var(--site-primary);
        }
    }
}

#CheckoutSpaRoot footer {
    background-color: #001e45;
}

.ImgButWrap a:hover,
.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll a:hover,
.SliderContain .ui-slider-handle::after,
.FlanProdDet .addToBasketContainer .ImgButWrap a:hover,
.lillBasket #aCheckout:hover,
.PinWrapText .addToBasketContainer .ImgButWrap span a:hover,
.Profile #btnSaveChanges:hover,
.lillBasket #divBagItems #divButtons #aViewBag,
.CheckWrap input.ContinueOn:hover,
.Responsive .storefinderSearchButtons input:hover {
    background-color: #fff;
    color: #000 !important;
    border: 1px solid #0f1a30;
}

.Responsive .storefinderSearchButtons input {
    border: 1px solid #0f1a30;
}

.lillBasket #aCheckout:hover {
    color: #000 !important;
}

.bsheaderIcons #topLinkMenu .TopLink > a .hidden-xs:hover,
.bsheaderIcons a.login .hidden-xs:hover,
#topLinkMenu ul.TopLinkMenu li a .hidden-xs:hover,
.Responsive .IconBagandWish #divBag #bagTotalContainer #bagTotal:hover,
.LanguageCurrency .currencyLanguageSlider li:hover label,
#currencyLanguageSelector .spanCurrencyLanguageSelector .currencyTitle:hover {
    color: #bbb;
}

.bsheaderIcons #topLinkMenu .TopLinkDrop .TopSubLinkMenu li.TopSubViewAll a .hidden-xs:hover {
    color: #000;
}

.SliderContain .ui-slider-handle:first-of-type::after {
    background-color: #ddd;
}

.dbc {
    color: #0f1a30;
}

.FlanProdDet .addToBasketContainer.addToBagInProgress .ImgButWrap a {
    background-color: rgba(246, 195, 202, 0.5);
}

.bsheaderIcons #topLinkMenu ul li.SignOut a:hover {
    background-color: #1c1c1c;
}

.progressBarActive {
    background-color: #888;
}

.progressBarItem {
    background-color: #ddd;
}

.accordionMenuContentWrap > ul li,
.accordionMenuContentWrap > ul li:hover,
.accordionMenuContentWrap #liMobileCurrencySelector,
.accordionMenuContentWrap #liMobileCurrencySelector:hover {
    background-color: #bbb;
}

.accordionMenuContentWrap .shop > ul li#liAllDepts {
    display: none;
}

.OrderComplete h1,
.OrderComplete h1::before,
.OrderComplete h1::after {
    background-color: #0f1a30;
}

.CheckWrap .SignupConfirm {
    border-color: #0f1a30;
}

.CheckWrap .SignupConfirm.OptOut {
    border-color: #e0e0e0;
}

.mp-back {
    background: rgba(256, 256, 256, 0.1);
}

.RightPane1 .c_GreyBox {
    background: none;
}

.piActiveDot {
    background-color: #000 !important;
}

nav#topMenu li.root > ul .redHeader > a {
    color: #c60b0b;
}

.CareersTopMenu a {
    color: #fff;
}

.CareersBrand .topLevelLink,
.CareersBrand .topLevelLink:hover {
    background-color: #000;
    border-bottom: solid 1px #000;
}

.MenuText {
    color: #fff;
}

.CareersBrand td.vacancy-more-info-btn > a {
    background-image: none;
}

.CareersBrand .mobile-menu-container .navbar-header {
    background-color: #181816;
    border: 1px solid #fff;
}

.CareersBrand .navbar-nav > li > a {
    border-right: 1px solid #fff;
}

.CareersBrand .mobile-menu-container .navbar-header .navbar-toggle .icon-bar {
    background-color: #fff;
}

@media (max-width: 767px) {
    .menulinks li {
        background-color: #000;
    }

    .menulinks li a {
        color: #fff;
    }
}

@media (max-width: 1021px) and (min-width: 768px) {
    #findAstore {
        background-image: none;
        text-indent: -9999px;
        white-space: nowrap;
    }
}

.personalisation,
.preorder {
    color: #444;
}

.FooterCardIcons.gbp,
.FooterCheck .ThreeHelpTips .CardPayments .CheckFootImg.gbp,
.BasketCardsIcons .PayImage.gbp,
.fashBasketCardsIcons .PayImage.gbp {
    background-image: url("/images/payment/cardpayments-gbp.png");
}

#helpMenu {
    padding: 0 10px 20px;
}

#helpMenu h2 {
    display: none;
}

#helpMenu .sidebar-nav li {
    border: none;
    margin-bottom: 10px;
    font-size: 1em;
}

#helpMenu .sidebar-nav li a {
    text-align: center;
    background-color: #f3f3f3;
    text-transform: uppercase;
    padding: 12px 0;
    color: #9e9e9e;
    transition: all 400ms linear 0s;
}

#helpMenu .sidebar-nav li a:hover {
    background-color: #000;
    color: #fff;
}

.Help_Title,
.c_BlackTitle .c_Title {
    line-height: 1.2em;
    margin-top: 9px;
    font-size: 1em;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    padding: 12px 15px;
}

.c_BlackTitle .c_Title {
    background-color: #000 !important;
}

.allLetterBrandHeader,
.allLetterBrandFooter {
    display: none;
}

.allLettersBrand {
    margin: 0;
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
    width: 100%;
}

.letTilte a {
    font-size: 4em;
    font-weight: var(--fw-bold);
    color: #181816;
    min-width: 75px;
    text-align: center;
}

.c_GreyBox {
    background: #fff;
}

.TopHeader h1,
.TopHeader p {
    font-size: 2.6em;
}

.TopHeader h1 a {
    text-transform: uppercase;
}

#contactuscontent .SubjectDrop label {
    text-transform: uppercase;
}

.InputContactGroup label {
    text-transform: uppercase;
}

.InputContactGroup div#FormOptions a {
    text-transform: uppercase;
}

.BasketNew .PromoCodeInput input {
    border: none;
    box-shadow: none;
    background-color: #ececec;
}

.newBasketPromoCode .PromoCodeBut a {
    background-color: #888;
    background-image: none;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 0 #fff;
}

.newBasketPromoCode .PromoCodeBut a:hover {
    background-color: #333 !important;
}

.siblingcategory {
    display: none;
}

a:hover,
a:focus,
nav#topMenu li.root > ul li a:hover,
#findAstore a:hover,
nav#topMenu > ul .NewMenuWrap li.level2 a:hover,
.flanFooterContainer li a:hover,
.basketLink .langlogin a.login:hover,
.spanCurrencyLanguageSelector p:hover,
.breadcrumb ol li a:hover,
.SortQtyName a:hover,
.Responsive .FilterListItem a:hover *,
.s-producttext-top-wrapper a:hover span span,
.lillBasket .BaskName:hover,
.letItems a:hover,
#catCopyMobileReadMoreLess:hover,
#topLinkMenu ul.TopLinkMenu li.TopSubViewAll a:hover {
    text-decoration: none;
}

nav#topMenu ul li.root > a:hover {
    color: #000;
}

.CheckWrap .PaymentMethodList .PointsCardPay,
.CheckWrap .savedcard .PaymentType_PointsCardPay {
    background-position: -200px -223px;
}

.DeliveryOptions .DeliveryOptionsItem.active .InnerMethods {
    background-color: #888;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active,
.DeliveryOptions .DeliveryOptionsItem .SpendVoucher {
    background-color: #333;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active::after {
    border-left: 7px solid #333;
}

.DeliveryOptionsItem.active .fullwidthlabel {
    border: 1px solid #333;
}

.Checkout .ContentWrapper {
    padding-top: 0;
    overflow: visible;
}

.Checkout .ContentWrapper .mainBody .row > .col-xs-12 {
    min-height: 0;
}

.Checkout #BodyWrap {
    overflow: hidden;
}

.OrderComplete {
    padding-top: 75px;
}

.OrderComplete h1 {
    position: absolute;
    margin: 0;
    top: 0;
    width: 100%;
    padding: 15px 0;
}

.OrderComplete h1::before,
.OrderComplete h1::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}

.OrderComplete h1::before {
    left: -50%;
}

.OrderComplete h1::after {
    right: -50%;
}

.OrderComplete h1 em::before {
    background-position: -211px -89px;
}

.OrderCompleteLogin,
.OrderCompleteRegistrationForm,
.OrderCompleteRegistrationDone {
    border: none;
}

.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    max-width: 250px;
    margin: 0 auto;
    padding: 7px;
    background-color: #fff !important;
    color: #000 !important;
    border: 2px solid #000;
    text-transform: uppercase;
}

.OrderCompleteRegistration .ContinueOn:hover,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction:hover {
    background-color: #eee !important;
}

#OrderCompleteRegisterModalCancel,
#OrderCompleteRegisterModalContinue,
.accordionMenuContentWrap .shop .menuitemtext {
    color: #000 !important;
}

#OrderCompleteRegisterModalCancel,
#Body.Profile #BodyWrap,
#Body.WishListEvent #BodyWrap {
    background-color: #fff !important;
}

#OrderCompleteRegisterModalContinue {
    background-color: #0f1a30 !important;
}

.Login .loginContainer .ImgButWrap a::after,
.Login .submitWrap .ImgButWrap a::after,
.submitBut .ImgButWrap a::after,
.BasketNew .primary2.ImgButWrap a::after,
.CheckWrap .ImgButWrap a.dnnPrimaryAction::after {
    content: none;
}

.divPreorder .preOrderbuttons .ImgButWrap a {
    font-weight: var(--fw-bold);
    text-shadow: none;
}

.divPreorder .preOrderbuttons .ImgButWrap a span {
    position: relative;
}

.divPreorder .preOrderbuttons .ImgButWrap a span::before {
    bottom: -4px;
}

.divPreorder .preOrderbuttons .preorderCancelButton {
    border: none !important;
    color: #fff !important;
    padding: 15px 5px;
}

.divPreorder .preOrderbuttons .preorderCancelButton,
.divPreorder .preOrderbuttons .preorderCancelButton:hover {
    background-color: #ccc !important;
}

@media (max-width: 1021px) {
    .FlanProdDet .AddToBagBar .AddToBagBarSection .pdpPrice {
        color: #fff;
    }
}

.preOrderbuttons .ImgButWrap {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
}

.CheckoutHeader > div,
.CheckoutHeader > a {
    width: 33.333333%;
}

.CheckWrap .ImgButWrap a.dnnPrimaryAction,
.CheckWrap .AddressContainBut .ContinueOn,
.CheckWrap .CheckStoreBut .ContinueOn,
.CheckWrap .ContinueButtonWrapperTop .ContinueOn,
.CheckWrap .ContinueButtonWrapperMobTop .ContinueOn,
.CheckWrap .ContinueButtonWrapperBottom .ContinueOn,
.DeliveryToContent .AddNewAddressCheck .ContinueOn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: var(--fw-bold);
    text-shadow: none;
    letter-spacing: 1px;
}

.CheckWrap .ImgButWrap a.dnnPrimaryAction span {
    position: relative;
}

@media (min-width: 768px) {
    .CheckWrap .CheckStoreBut .ContinueOn {
        font-size: 12px;
        letter-spacing: 0;
    }
}

@media (min-width: 1022px) {
    .LogoCheck.col-xs-6 {
        right: 33.333333%;
    }
}

.LogoCheck img {
    max-height: 20px;
}

@media (min-width: 768px) and (max-width: 1021px) {
    .LogoCheck.col-xs-6 {
        right: 26.5%;
    }
}

@media (max-width: 1021px) {
    .FlanProdDet .AddToBagBar .AddToBagBarSection .pdpPrice,
    .FlanProdDet .AddToBagBar .AddToBagBarSection .spnFrom {
        color: #fff;
    }

    .CheckoutHeader .padLock,
    .CheckoutHeader .CheckoutProgressBack {
        width: 26.5%;
    }

    .CheckoutHeader .LogoCheck {
        width: 47%;
    }
}

header {
    background-color: #fff;
}

.CheckoutHeader .headerSecureCheckout,
.CheckoutHeader .headerSummary .summaryText,
.CheckoutHeader .headerSummary .summaryPrice,
.CheckoutHeader .headerRightSecureCheckout {
    color: #000;
}

.checkChevron span {
    background-position: -20px -115px;
}

.headerSummaryContainer {
    background-color: #f5f5f5;
}

.securePad {
    background-position: -276px -107px;
}

@media (min-width: 1025px) {
    .securePad {
        background-position: -368px -144px;
    }
}
