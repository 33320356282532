.HeaderWrap {
    top: 0;

    .ToplinksGroup {
        max-width: 1760px;
        padding: 0;
    }

    .TopNavBar .container-fluid {
        padding: 0;
    }

    .TopNavBar .container-fluid > .row {
        display: grid;
        grid-template-columns: 215px 1fr 215px;
        grid-template-rows: 71px;
        grid-template-areas: "LogoWrap SearchWrap NavWrap";
        column-gap: 10px;
        margin: 0;
        padding: 0 15px;

        @media (max-width: 1021px) {
            grid-template-columns: 150px 1fr 150px;
            grid-template-rows: 55px;
        }

        @media (max-width: 767px) {
            grid-template-areas:
                "LogoWrap . NavWrap"
                "SearchWrap SearchWrap SearchWrap";
            grid-template-columns: minmax(0, 150px) 1fr minmax(0, 150px);
            grid-template-rows: 55px 66px;
            column-gap: 5px;
        }

        > div {
            width: 100%;
        }

        .LogoWrap {
            grid-area: LogoWrap;
        }

        > .search {
            grid-area: SearchWrap;
        }

        .ControlWrap {
            grid-area: NavWrap;
            padding: 0;
        }

        .mobileSearch {
            display: none;
        }
    }
}

.HeaderWrap,
.HeaderTopSpacer {
    height: 115px;

    @media (max-width: 1022px) {
        height: 55px;
    }

    @media (max-width: 767px) {
        height: 121px;
    }

    .TopNavBar {
        height: 71px;
    }
}

.HeaderWrap .LogoWrap {
    padding: 0;

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
            width: 100%;
            max-width: 200px;
        }
    }
}

.ToplinksGroup .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    @media (max-width: 767px) {
        padding: 0;
    }

    .dvSearch {
        border: 1px solid var(--site-dark-grey);
        max-width: 640px;

        @media (max-width: 1022px) {
            max-width: 344px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }

        #txtSearch {
            border: none;
            width: 100%;
            height: 40px;
            padding: 5px 5px 5px 45px;

            &:focus {
                border: none;
            }
        }

        #cmdSearch {
            position: absolute;
            left: 0;
            right: auto;
            background-color: var(--site-white);
            width: 40px;
            height: 40px;
            cursor: pointer;

            .global-icon-search {
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
            }
        }
    }
}

.ControlWrap .bsIconsSearch {
    display: grid;
    grid-template-columns: 1fr 66.6666%;
    grid-template-rows: 71px;
    margin: 0;

    @media (max-width: 1021px) {
        grid-template-columns: 66.6666% minmax(40px, 1fr);
        grid-template-rows: 55px;
    }

    &::before {
        display: none;
    }

    .bsheaderIcons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .IconBagandWish {
        display: flex;
        align-items: center;

        #divBag {
            height: 36px;

            #divBagTotalLink {
                width: 100%;
                float: none;
                text-align: center;
                padding: 0;
                font-size: 1em;
            }

            #divBagTotalLink a#aBagLink {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                min-width: 0;
                padding: 0;
                background: none;
                overflow: visible;

                #bagQuantityContainer {
                    position: relative;
                    font-size: 1em;
                }

                .global-icon-bag svg {
                    width: 24px;
                }
            }
        }

        .WishList {
            overflow: visible;

            a#aWishListLink {
                overflow: visible;

                .global-icon-wishlist svg {
                    width: 26px;
                    height: 24px;
                }
            }
        }

        .WishList #lblWishListCount,
        #divBag #bagQuantity {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--site-primary-light);
            color: var(--site-white);
            width: 26px;
            height: 26px;
            border-radius: 50%;
            position: absolute;
            top: 1px;
            right: -28px;
            left: auto;
            transform: translate(-50%, -50%);
            font-size: 1em;
            background-image: none;
            padding: 0;

            @media (max-width: 767px) {
                width: 22px;
                height: 22px;
                right: -22px;
            }

            &.hide-wishlist,
            &.hide-count {
                display: none;
            }
        }
    }

    #mobMenuContainer {
        .menu-trigger {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 0;
            border: none;
            background: none;

            .global-icon {
                pointer-events: none;
            }

            .global-icon-menu {
                .PullMenuActive & {
                    display: none;
                }
            }

            .global-icon-cross {
                display: none;

                .PullMenuActive & {
                    display: block;
                }
            }
        }
    }
}

.u-vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.ui-autocomplete {
    margin-top: -3px;
}
