.WishList #aWishListLink {
    display: flex !important;
    height: 100%;
    justify-content: center;
    align-items: center;

    .wishQuantityContainer {
        position: relative;
    }

    .wishQuantityContainer .WishIcon {
        display: flex;

        .global-icon-wishlist {
            display: flex;

            svg path {
                fill: var(--site-black);
            }

            svg path.inner {
                fill: var(--site-white);
            }
        }
    }
}

.WishList #aWishListLink:hover {
    .wishQuantityContainer .WishIcon .global-icon-wishlist {
        svg path {
            fill: #bbb;
        }

        svg path.inner {
            fill: var(--site-white);
        }
    }
}

#divWishList .wishlist-summary {
    top: 55px;
    z-index: 2;

    .wishlist-summary-inner {
        margin: 0;
    }

    .wishlist-summary-header .wishlist-summary-tick .tick-tick {
        fill: var(--site-white);
    }

    .wishlist-summary-footer {
        text-align: center;

        .ctaBtn {
            border: 1px solid var(--site-primary);

            &:hover {
                color: var(--site-primary);
            }
        }
    }
}

.wishlist-page {
    max-width: 1920px;
    padding-top: 20px;
    padding-left: calc(4px * 4);
    padding-right: calc(4px * 4);

    @media (min-width: 1440px) {
        padding-left: calc(4px * 24);
        padding-right: calc(4px * 24);
    }

    @media (min-width: 1022px) {
        padding-left: calc(4px * 12);
        padding-right: calc(4px * 12);
    }

    @media (min-width: 768px) {
        padding-left: calc(4px * 6);
        padding-right: calc(4px * 6);
    }

    &__empty {
        .wishlist-page__primary-button {
            transition: all 0.25s linear 0s;
            border: solid 1px var(--site-primary);

            &:hover span {
                color: var(--site-primary);
            }
        }
    }

    .wishlist-page__header {
        top: 131px;

        @media (min-width: 1022px) {
            top: 125px;
        }

        @media (min-width: 768px) {
            top: 65px;
        }

        .wishlist-page__primary-button {
            transition: all 0.25s linear 0s;
            border: solid 1px var(--site-primary);

            &:hover span {
                color: var(--site-primary);
            }
        }
    }
}
